<template>
    <div class="c_content_container reference travelto">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/travelto/logo.svg"
                            alt="트래블투"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />여행사 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>여행사를 타켓으로한 템플릿 사이트 입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>생동감이 넘치는</h1>
                    <p>
                        여행사 사이트 답게 컨텐츠양이 많다보니 사용자들이 이용시
                        복잡하지 않도록
                        <strong>필요한 정보들만</strong> 노출시켰으며 부드럽고
                        따뜻한 느낌을 위해 <strong>곡선을 사용</strong>했습니다.
                        또한, 이미지가 많은 컨텐츠들이다 보니 메인컬러외에는
                        색사용을 자제하였습니다.
                    </p>
                </div>
                <div>
                    <img
                        src="@/assets/images/reference/travelto/concept.svg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section main backgrounded">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>메인페이지</h1>
                    <p>
                        메인페이지의 메인 배너는 현재 인기있는 여행지 또는
                        진행중인 프로모션 상품을<br />
                        보여줌으로써 상황에 따라
                        <strong>유연하게 변화</strong>할 수 있습니다. 배너 바로
                        아래 카테고리별 <strong>검색 섹션</strong>을 배치하여
                        사용자들이 접근하기 쉽게 하였습니다.
                    </p>
                </div>
                <div class="mockup_wrap"></div>
            </div>
        </section>

        <section class="pt_section detail contents">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONTENTS</h6>
                    <h1>다양한 카테고리</h1>
                    <p>
                        <strong
                            >이 주의 베스트 상품, md추천 여행지,
                            테마여행</strong
                        >
                        등 다양한 카테고리로 나눠져 있어 사용자가 원하는 여행을
                        한눈에 보고 비교해 볼 수 있습니다.
                    </p>
                </div>
                <div class="conts_wrap">
                    <div class="gd_white">
                        <img
                            src="@/assets/images/reference/travelto/content01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="gd_white">
                        <img
                            src="@/assets/images/reference/travelto/content02.jpg"
                            alt=""
                        />
                    </div>
                    <div class="gd_white">
                        <img
                            src="@/assets/images/reference/travelto/content03.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section dinfo">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DETAIL INFORMATION</h6>
                    <h1>정보의 구분화</h1>
                    <p>
                        많은양의 텍스트를 정보별로 구분을 짓는게 중요했으며
                        특수문자나 번호를 붙여서 가독성을 높였으며, 특별히
                        강조할 부분은 폰트의 색상을 다르게 하여 주목성을
                        높였습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_centered image_shadow">
                        <img
                            src="@/assets/images/reference/travelto/detailinfo01.png"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/travelto/detailinfo02.png"
                                alt=""
                            />
                        </div>
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/travelto/detailinfo03.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section mypg">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MY PAGE</h6>
                    <h1>마이페이지</h1>
                    <p>
                        마이페이지 메뉴는 <strong>직관적인 아이콘</strong>을
                        사용하여 심미성과 주목성을 높였습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_centered image_shadow">
                        <img
                            src="@/assets/images/reference/travelto/mypage01.png"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/travelto/mypage02.png"
                                alt=""
                            />
                        </div>
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/travelto/mypage03.png"
                                alt=""
                            />
                        </div>
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/travelto/mypage04.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/travelto/responsive01.png"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/travelto/responsive02.png"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/travelto/responsive03.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        사용자들의 정보의 이해도를 높이기 위해 직관적인 라인
                        아이콘을 사용했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/travelto/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/travelto/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/travelto/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/travelto/icon04.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/travelto/icon05.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/travelto/icon06.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        메인컬러는 여행사의 액티비티하면서 에너지넘치는 무드를
                        살리기위해 <strong>오렌지 컬러</strong>를 사용했으며
                        반대로 받춰주는 컬러는 명도가 너무 낮지않은
                        <strong>블랙</strong>을 사용하여 균형을 맞췄습니다.<br />
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Orange</p>
                        <p>R 229 G 90 B 58</p>
                        <p>#0F0F0F</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Black</p>
                        <p>R 51 G 51 B 51</p>
                        <p>#333333</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>Noto Sans KR</strong>, 영문과 숫자는
                        <strong>Roboto</strong>를 사용해 가독성과 심미성을
                        높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Roboto<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages gd_white backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.travelto .image_shadow {
    border-radius: 3rem;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.travelto .gd_white::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    max-width: unset;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
        bottom,
        var(--color-bg-light) 0%,
        var(--color-bg-dark) 80%
    );
    background: -webkit-linear-gradient(
        bottom,
        var(--color-bg-light) 0%,
        var(--color-bg-dark) 80%
    );
    background: linear-gradient(
        to top,
        var(--color-bg-white) 0%,
        rgba(0, 0, 0, 0) 30%
    );
}
.reference.travelto .main_banner {
    background-image: url("~@/assets/images/reference/travelto/img_mainBanner.jpg");
}
.reference.travelto .main {
    min-height: 250rem;
    background: url("~@/assets/images/reference/travelto/main.png") no-repeat
        top center/cover;
}
.reference.travelto .main::before {
    background: none;
}
.reference.travelto .contents .conts_wrap > div {
    position: relative;
    overflow: hidden;
    border-radius: 3rem;
}
.reference.travelto .contents .conts_wrap > div:not(:last-child) {
    margin-bottom: 10rem;
}
.reference.travelto .dinfo {
    background: url("~@/assets/images/reference/travelto/detailinfo_bg.svg")
        no-repeat center top;
}
.reference.travelto .mockup_wrap .mockup_basic {
    margin-top: 5rem;
}
.reference.travelto .colorType_01 .box:nth-child(1) {
    background: #e55a3a;
}
.reference.travelto .colorType_01 .box:nth-child(2) {
    background: #333333;
}
.reference.travelto .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.travelto .fontType_01 .box_02 {
    font-family: "Roboto", sans-serif;
}
.reference.travelto .pages {
    min-height: 170rem;
    background: url("~@/assets/images/reference/travelto/pages.png") no-repeat
        center bottom/cover;
}
@media screen and (max-width: 768px) {
    .reference.travelto .dinfo .mockup_basic .web {
        width: 100%;
    }
}
</style>
