<template>
    <div class="c_content_container reference giveheart">
        <!--메인배너 START-->
        <div class="main_banner p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="icon_wrap">
                        <img
                            src="@/assets/images/reference/giveheart/icon_giveheart.svg"
                            alt=""
                        />
                    </div>
                    <h1 class="title">기브하트<br />앱 서비스 개발</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱, 개발</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>
                                기브하트는 부고 정보를 작성 및 공유하고,<Br />
                                조의금과 마음을 전달할 수 있는 서비스입니다.
                            </p>
                        </li>
                    </ol>
                    <div class="btn_wrap-mobile">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.koreapg.giveheart&hl=ko&gl=US">구글 다운로드</a>
                        <a target="_blank" href="https://apps.apple.com/kr/app/%EA%B8%B0%EB%B8%8C%ED%95%98%ED%8A%B8/id1661531947">앱스토어 다운로드</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section challenge">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Challange</h6>
                    <h1>마음을 전달하는 앱서비스<br /><span>기브하트</span></h1>
                    <p>
                        기브하트는 부고 정보를 알리고, 조의금과 마음을 전달할 수
                        있는 앱 서비스입니다.
                    </p>
                </div>
                <div class="gridType">
                    <div class="col3">
                        <div class="inner shadow_inset-light">
                            <div class="image_wrap">
                                <img
                                    src="@/assets/images/reference/giveheart/img_grid01.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h1>부고를 등록하는 사용자</h1>
                            <p>
                                부고 정보를 작성하고, 지인들에게 전달할 수
                                있습니다. 또한 현장을 관리해줄 매니저를 직접
                                초대할 수 있으며, 장례가 종료된 후엔 감사장을
                                전달할 수 있습니다.
                            </p>
                        </div>
                    </div>
                    <div class="col3">
                        <div class="inner shadow_inset-light">
                            <div class="image_wrap">
                                <img
                                    src="@/assets/images/reference/giveheart/img_grid02.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h1>부고를 전달받는 사용자</h1>
                            <p>
                                상주가 등록한 부고를 확인하고 조문메시지를
                                작성할 수 있으며, 조의금을 결제할 수 있습니다.
                            </p>
                        </div>
                    </div>
                    <div class="col3">
                        <div class="inner shadow_inset-light">
                            <div class="image_wrap">
                                <img
                                    src="@/assets/images/reference/giveheart/img_grid03.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h1>현장 매니저로 초대된 사용자</h1>
                            <p>
                                상주로부터 초대된 사용자들은 초대를 수락한 후
                                현장에서 온사이트 매니저를 활용하여 기간동안
                                장례 현장을 관리할 수 있습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section solution backgrounded">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Solution</h6>
                    <h1>
                        사용자의 손 끝에<br /><span>고급스러움</span>과
                        <span>부드러움</span>을
                    </h1>
                    <p>
                        기브하트 서비스를 작업하면서 가장 중요하게 가져갔던
                        키워드는 <strong>'고급스러움'</strong> 그리고
                        <strong>'부드러움'</strong>이었습니다.
                        <br />
                        전체적인 UI는
                        <strong>블랙 앤 그레이 계열의 모노톤</strong>을 사용하여
                        고급스러우면서도 정적인 분위기를 표현하였고, 프로그레스
                        바/체크박스/차트 등의 특정한 UI에서는 로고의 색감인
                        <strong>레드 컬러</strong>를 활용하여 서비스의 정체성을
                        확립하고, 무거운 분위기를 다소 풀어줄 수 있는 포인트를
                        주었습니다. <Br /> 또한 서비스의 고급스러운 분위기를
                        유지하기 위해 <strong>각진 형태의 UI</strong>들과
                        <strong>라인 형태의 아이콘</strong>들을 사용하였으며,
                        일러스트 위주의 이미지들은 다소 딱딱해질 수 있는 화면에
                        부드러운 인상을 주기 위해 사용되었습니다.
                    </p>
                </div>
            </div>
        </section>

        <section class="pt_section detail detail_01 subType_01">
            <div class="subtitle_background">
                <div class="filled"></div>
                <div class="line"></div>
            </div>
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>An Obituary Notice</h6>
                    <h1>부고장</h1>
                    <p>
                        상세 부고장 화면입니다.<br />
                        상단의 이미지는 고급스러우면서도 잔잔하고 정적인
                        분위기를 유지하기 위해 블랙의 배경에 금색 띄를 둘러
                        표현하였습니다.<br />
                        하단의 섹션들은 부고장의 정보들을 사용자들이 쉽게 읽을
                        수 있도록 배경색을 구분한 표로 정리하였으며, 이와
                        상반되는 어두운 컬러의 버튼들은 시각적으로 눈에 잘 띄어
                        사용자들이 기능들을 인식하기 쉽도록 작업하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/giveheart/img_main.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mobile">
                        <img
                            src="@/assets/images/reference/giveheart/img_main_mockup.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_01">
            <div class="subtitle_background">
                <div class="filled"></div>
                <div class="line"></div>
            </div>
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Write An Obituary</h6>
                    <h1>부고 작성</h1>
                    <p>
                        부고를 작성할 수 있는 프로세스 화면입니다.<br />
                        상단의 프로세스 바가 현재 작성하고 있는 페이지가
                        어디쯤인지, 작성해야 할 내용이 어느 정도 남았는지를
                        시각적으로 인지할 수 있도록 기능하고 있습니다.
                    </p>
                </div>
                <div class="image_centered">
                    <img
                        src="@/assets/images/reference/giveheart/img_write.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_01">
            <div class="subtitle_background">
                <div class="filled"></div>
                <div class="line"></div>
            </div>
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Manage An obituary</h6>
                    <h1>부고 관리</h1>
                    <p>
                        사용자가 작성한 부고들을 직접 관리할 수 있는
                        화면입니다.<br />
                        버튼 하단의 화살표를 터치해 숨겨진 기능들을 확인할 수
                        있도록 작업하였습니다.
                    </p>
                </div>
                <div class="image_centered">
                    <img
                        src="@/assets/images/reference/giveheart/img_manage.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_01">
            <div class="subtitle_background">
                <div class="filled"></div>
                <div class="line"></div>
            </div>
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Condolences History</h6>
                    <h1>부조 내역</h1>
                    <p>
                        사용자가 송금한 부조 내역을 확인할 수 있는
                        화면입니다.<br />
                        상단의 필터 영역에서 기간을 선택하면 기간 내에 사용자가
                        결제한 부조 내역들과 총액을 확인할 수 있으며, 표 안의
                        화살표를 터치하면 세부 내역을 확인할 수 있는 화면으로
                        넘어가도록 제작하였습니다.
                    </p>
                </div>
                <div class="image_centered">
                    <img
                        src="@/assets/images/reference/giveheart/img_history.png"
                        alt=""
                    />
                </div>
            </div>
        </section>
        <!--section END-->

        <!--footer START-->
        <!-- <div class="pt_footer">
            <footer>
                <img
                    src="@/assets/images/reference/giveheart/logo_giveheart.svg"
                    alt=""
                />
            </footer>
        </div> -->
        <!--footer END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.giveheart .pt_section + .pt_section {
    border-top: 2rem solid #f5f5f5;
}
.reference.giveheart .main_banner {
    background-image: url("~@/assets/images/reference/giveheart/img_mainBanner.jpg");
}
.reference.giveheart .challenge .intro_wrap h1 span {
    color: var(--color-giveheart);
}
.reference.giveheart .gridType .inner .image_wrap {
    border: 3px solid var(--color-giveheart);
}
.reference.giveheart .gridType .text_wrap h1 {
    border-color: var(--color-giveheart);
}
.reference.giveheart .solution.backgrounded {
    background: url("~@/assets/images/reference/giveheart/Bg_Solution.png")
        no-repeat right -20rem center/cover;
}
.reference.giveheart .solution .intro_wrap h1 span {
    color: #f5a455;
}
.reference.giveheart .detail_01 .pt_inner .web::before {
    content: "** 해당 화면은 임의로 넣어둔 예시 화면임을 알립니다.";
    color: #999;
    font-size: var(--font-size-small);
    line-height: 5;
}
.reference.giveheart .pt_footer {
    background-color: #222;
}
.reference.giveheart .image_centered img,
.reference.giveheart .image_right img,
.reference.giveheart .image_left img {
    max-height: 900px;
}

@media screen and (max-width: 768px) {
    .reference.giveheart .detail .mockup_basic {
        flex-direction: column-reverse;
    }
    .reference.giveheart .detail .mockup_basic .right {
        width: 30%;
        min-width: 20rem;
        margin: 0 auto;
    }
}
</style>
