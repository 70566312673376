<template>
    <div class="c_content_container reference iptk">
        <!--메인배너 START-->
        <div class="main_banner p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/iptk/logo.svg"
                            alt="아이피티케이"
                        />
                    </div>
                    <h1 class="title">아이피티케이<br />사이트 개발</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱, 개발</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>
                                IPTK는 스타트업이 성장할수 있도록 교육과
                                멘토링<br />투자유치까지 다양한 프로그램을
                                보유하고 있습니다.
                            </p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="https://www.iptk.co.kr/" target="_blank"
                            >웹사이트 바로가기</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section overview">
            <div class="pt_inner">
                <div class="image_centered image_shadow">
                    <img
                        src="@/assets/images/reference/iptk/symbol.svg"
                        alt=""
                    />
                </div>
                <div class="intro_wrap">
                    <h1>스타트업 혁신발전소 IPTK</h1>
                    <p>
                        IPTK는 스타트업이 성장할수 있도록 교육과 멘토링
                        투자유치까지 다양한 프로그램을 보유하고 있습니다.
                        사이트에는 IPTK가 하는일, 비전과 미션,기업소개, 다양한
                        프로그램 소개 및 신청까지
                        <strong>다양한 컨텐츠</strong>를 한눈에 파악하고
                        이용하실수 있도록 하였습니다.
                    </p>
                </div>
            </div>
        </section>

        <section class="pt_section detail main_pg">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>임팩트있는 메인</h1>
                    <p>
                        메인화면에서 메인배너 영역을 한화면에 꽉채워 시원하면서
                        임팩트있게 iptk가 보여주고자 하는 아이텐티티를
                        효과적으로 전달하도록 구성했습니다.
                    </p>
                </div>
                <div class="image_centered">
                    <img src="@/assets/images/reference/iptk/main.png" alt="" />
                </div>
            </div>
        </section>

        <section class="pt_section about">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>ABOUT IPTK</h6>
                    <h1>다양한 컨텐츠</h1>
                    <p>
                        IPTK 에는 iptk ceo인사말, iptk가 하는일, 조직 운영방식,
                        비전&미션 그리고 오시는길 등 다양한 정보를
                        <strong>인포그래픽</strong>과
                        <strong>아이콘</strong>등을 사용하여
                        <strong>시각적으로 한눈에</strong> 파악하기쉽도록
                        구성하였습니다.
                    </p>
                </div>
                <video autoplay controls loop>
                    <source
                        src="@/assets/movie/aboutiptk01.mp4"
                        type="video/mp4"
                    />
                </video>
                <div class="mockup_basic">
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/iptk/about01.png"
                            alt=""
                        />
                    </div>
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/iptk/about02.png"
                            alt=""
                        />
                    </div>
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/iptk/about03.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section reference">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>referenceS</h6>
                    <h1>원하는 정보를 쉽게</h1>
                    <p>
                        검색창과 리스트들을 분야별로 카테고리화 하여 다양한
                        리스트들중 원하는 정보를 검색하여 쉽게 찾을수 있도록
                        했으며, <strong>카테고리명 태그</strong>를 달아서
                        전체보기를 할때도 어떤분야인지 직관적으로 알아볼 수
                        있도록 했습니다.
                    </p>
                </div>
                <div class="image_centered">
                    <img
                        src="@/assets/images/reference/iptk/reference01.png"
                        alt=""
                    />
                </div>
                <div class="image_centered">
                    <img
                        src="@/assets/images/reference/iptk/reference02.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section solution">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도 깨지지 않는
                        레이아웃을<br />보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/iptk/responsive01.png"
                            alt=""
                        />
                    </div>
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/iptk/responsive02.png"
                            alt=""
                        />
                    </div>
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/iptk/responsive03.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section interaction">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>INTERACTION</h6>
                    <h1>역동적인 모션</h1>
                    <p>
                        모션 효과를 적용하여 입체감과 역동감을 살렸으며,<br />정보에
                        좀 더 집중할수 있도록 주목성을 높였습니다.
                    </p>
                </div>
                <video autoplay controls loop>
                    <source
                        src="@/assets/movie/interaction.mp4"
                        type="video/mp4"
                    />
                </video>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        퍼플색상을 사용하여 창의적이면서 혁신적인 IPTK의
                        아이텐티티를 표현했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Purple</p>
                        <p>R 101 G 98 B 164</p>
                        <p>##6562A4</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Light Gray</p>
                        <p>R 244 G 244 B 244</p>
                        <p>#f4f4f4</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>나눔스퀘어</strong>, 영문은
                        <strong>Urbanist</strong>로 각언어에 어울리는 가독성이
                        높은 폰트를 사용하였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            나눔스퀘어<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Urbanist<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        메인컬러 라인아이콘을 사용하여 사용자들이<br />컨텐츠를
                        좀 더 <strong>직관적으로</strong> 쉽게 이해할 수 있도록
                        했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/iptk/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/iptk/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/iptk/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/iptk/icon04.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/iptk/icon06.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/iptk/icon07.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700&display=swap");
.reference.iptk .main_banner {
    background-image: url("~@/assets/images/reference/iptk/img_mainBanner.jpg");
}
.reference.iptk .overview .image_centered {
    margin: 0 auto 6rem;
    max-width: 35rem;
    max-height: 35rem;
    box-shadow: 2px 3px 10px #ddd;
    border-radius: 35rem;
}
.reference.iptk .main_pg {
    position: relative;
    background: url("~@/assets/images/reference/iptk/main_bg.jpg") no-repeat
        center top/cover;
    background-size: auto 80rem;
    overflow: hidden;
    color: #fff;
}
.reference.iptk .main_pg .tit {
    color: #fff;
}
.reference.iptk .about video {
    border: 0.4rem solid var(--color-border-dark);
}
.reference.iptk video {
    width: 100%;
    height: 100%;
    border-radius: 3rem;
}
.reference.iptk .about .mockup_basic {
    margin-top: 10rem;
}
.reference.iptk .about .mockup_basic .image_shadow {
    border-radius: 4rem;
}
.reference.iptk .about .mockup_basic > div:nth-child(2) {
    margin-top: 3rem;
}
.reference.iptk .about .mockup_basic > div:nth-child(3) {
    margin-top: 6rem;
}
.reference.iptk .reference .pt_inner > div + div {
    margin-bottom: 6rem;
}
.reference.iptk .solution .mockup_basic {
    flex-wrap: wrap;
    justify-content: space-between;
}
.reference.iptk .solution .mockup_basic > div {
    border: 0.4rem solid var(--color-border-dark);
    overflow: hidden;
}
.reference.iptk .solution .mockup_basic > div:nth-of-type(1) {
    width: calc((100% - 4rem) / 1.5);
}
.reference.iptk .solution .mockup_basic > div:nth-of-type(2) {
    margin-top: 15rem;
    width: calc((100% - 4rem) / 5);
}
.reference.iptk .solution .mockup_basic > div:nth-of-type(3) {
    margin: 0 auto;
    width: calc((100% - 4rem) / 2.5);
}
.reference.iptk .interaction {
    color: var(--color-font-white);
    background: url("~@/assets/images/reference/iptk/interaction_bg.svg")
        no-repeat center top/cover;
}
.reference.iptk .fontType_01 .box_01 {
    font-family: "NanumSquare";
}
.reference.iptk .fontType_01 .box_02 {
    font-family: "Urbanist", sans-serif;
}
.reference.iptk .colorType_01 .box {
    border-radius: 3rem;
}
.reference.iptk .colorType_01 .box:nth-child(1) {
    background: #6562a4;
}
.reference.iptk .colorType_01 .box:nth-child(2) {
    background: #f4f4f4;
    color: var(--font-color-default);
}
.reference.iptk .pages {
    background: url("~@/assets/images/reference/iptk/pages.jpg") no-repeat
        center/cover;
}
@media screen and (max-width: 768px) {
    .reference.iptk .about .mockup_basic .image_shadow {
        border-radius: 7rem;
    }
    .reference.iptk .about .mockup_basic > div:nth-child(2) {
        margin-top: unset;
    }
    .reference.iptk .about .mockup_basic > div:nth-child(3) {
        margin-top: unset;
    }
    .reference.iptk .solution .mockup_basic > div:nth-of-type(1) {
        width: 100%;
    }
    .reference.iptk .solution .mockup_basic > div:nth-of-type(2) {
        margin-top: unset;
        width: 100%;
    }
    .reference.iptk .solution .mockup_basic > div:nth-of-type(3) {
        width: 100%;
    }
}
</style>
