<template>
	<div class="c_content_container work">
		<!-- 상단배너 START -->
		<section class="c_section c_banner banner_work">
			<div class="c_inner">
				<div class="img_wrapper work">
					<div class="icon_wrapper">
						<img src="@/assets/images/banner/icon_work.png" alt="아이콘" class="icon" />
					</div>
				</div>
			</div>
			<div class="c_inner">
				<div class="page_text">
					<h2 v-textMotion class="text_large">
						<span class="textMotion_target">
							<span class="textMotion_target"><span class="text_deco"></span>{{ $t("Reference Banner")
							}}</span>
						</span>
					</h2>
				</div>
			</div>
		</section>
		<!-- 상단배너 END -->

		<!-- 컨텐츠 START -->
		<section class="c_section reference">
			<!--filter START-->
			<div class="filter_wrap">
				<div class="c_inner">
					<div class="selectBox">
						<p>분류</p>
						<select v-model="selectTag" @change="setSelectTag($event)">
							<option value="all tag">전체</option>
							<option value="Responsive Web">반응형 웹</option>
							<option value="App">앱, 어플리케이션</option>
						</select>
					</div>
					<div class="selectBox">
						<p>연도</p>
						<select v-model="selectYear" @change="setSelectYear($event)">
							<option value="all year">전체</option>
							<option value="2023">2023년</option>
							<option value="2022">2022년</option>
							<option value="2021">2021년</option>
						</select>
					</div>
					<div class="searchBox">
						<p>검색</p>
						<input type="text" v-model="searchKeyword" />
						<button type="button">
							<div class="svg_wrap">
								<IconSearch viewBox="0 0 34.6 34.6" />
							</div>
						</button>
					</div>
				</div>
			</div>
			<!--filter END-->

			<!--contents START-->
			<div class="c_inner">
				<div class="content_wrap" v-for="(content, i) in contents" :key="i">
					<div class="year_title" v-if="content.month !== contents[i - 1]?.month">
						<h1>{{ content.year }}{{ $t("Year") }} {{ $t(content.month) }}</h1>
					</div>
					<router-link :to="content.url" class="content">
						<div class="image_area">
							<img :src="
								require(`@/assets/images/reference/${content.img}/img_mainBanner.${content.imgType}`)
							" />
						</div>
						<div class="text_area">
							<span class="flag">
								{{ $t(content.tag) }}
							</span>
							<div>
								<h1>{{ $t(content.title[0]) }} {{ $t(content.title[1]) }}</h1>
								<p>
									{{ $t(content.text) }}
								</p>
							</div>
							<h6 class="date">
								{{ content.year }}{{ $t("Year") }} {{ $t(content.month) }}
							</h6>
						</div>
					</router-link>
				</div>
			</div>
			<!--contents END-->
		</section>
		<!-- 컨텐츠 END -->
	</div>
</template>

<script>
import IconSearch from "@/components/SVG/IconSearch.vue";
import { referenceContents } from "@/data/referenceContents";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
	components: {
		IconSearch,
	},
	setup() {
		const { t } = useI18n();
		const selectTag = ref("all tag");
		const selectYear = ref("all year");
		const searchKeyword = ref("");
		const contents = ref(referenceContents);

		// 필터링 및 검색 로직 시작
		const setSelectTag = (e) => {
			if (e.target.value === "all tag") {
				if (selectYear.value === "all year") {
					contents.value = referenceContents;
					return;
				} else {
					contents.value = referenceContents.filter((item) => {
						return item.year === parseInt(selectYear.value);
					});
				}
			} else {
				if (selectYear.value === "all year") {
					contents.value = referenceContents.filter((item) => {
						return item.tag === e.target.value;
					});
				} else {
					contents.value = referenceContents.filter((item) => {
						return (
							item.tag === e.target.value &&
							item.year === parseInt(selectYear.value)
						);
					});
				}
			}
		};
		const setSelectYear = (e) => {
			if (e.target.value === "all year") {
				if (selectTag.value === "all tag") {
					contents.value = referenceContents;
					return;
				} else {
					contents.value = referenceContents.filter((item) => {
						return item.tag === selectTag.value;
					});
				}
			} else {
				if (selectTag.value === "all tag") {
					contents.value = referenceContents.filter((item) => {
						return item.year === parseInt(selectYear.value);
					});
				} else {
					contents.value = referenceContents.filter((item) => {
						return (
							item.tag === selectTag.value &&
							item.year === parseInt(selectYear.value)
						);
					});
				}
			}
		};

		watch(searchKeyword, () => {
			if (searchKeyword.value === "") {
				if (selectTag.value === "all tag") {
					if (selectYear.value === "all year") {
						contents.value = referenceContents;
					} else {
						contents.value = referenceContents.filter((item) => {
							return item.year === parseInt(selectYear.value);
						});
					}
				} else {
					if (selectYear.value === "all year") {
						contents.value = referenceContents.filter((item) => {
							return item.tag === selectTag.value;
						});
					} else {
						contents.value = referenceContents.filter((item) => {
							return (
								item.tag === selectTag.value &&
								item.year === parseInt(selectYear.value)
							);
						});
					}
				}
			} else {
				contents.value = contents.value.filter((item) => {
					return (
						t(item.title[0]).includes(searchKeyword.value) ||
						t(item.title[1]).includes(searchKeyword.value)
					);
				});
			}
		});
		// 필터링 및 검색 로직 종료

		return {
			contents,
			referenceContents,
			selectTag,
			selectYear,
			searchKeyword,
			setSelectTag,
			setSelectYear,
		};
	},
};
</script>

<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped>
	input,select{
		padding:0 2rem;
	}
</style>
