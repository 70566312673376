<template>
    <div class="c_content_container reference grandcasa">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/grandcasa/logo.svg"
                            alt="그랜드카사"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />호텔 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>호텔을 타켓으로한 템플릿 사이트 입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section detail concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>클래식 무드</h1>
                    <p>
                        그랜드 카사의 고급스러운 분위기를 사이트에 그대로 담기
                        위해<br />배경은 <strong>밝은 베이지 컬러</strong>로
                        선택하여 눈도 편안하면서 따뜻한 느낌이 들도록 했으며,
                        중요한 제목이라든지 비주얼적으로 강조됐으면 하는 곳에는
                        <strong>serif</strong> 타입의 폰트를 사용하여 보다
                        클래식하면서 차분한 느낌을 더했습니다.
                    </p>
                </div>
                <div class="img_wrap">
                    <img
                        src="@/assets/images/reference/grandcasa/concept.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>메인페이지</h1>
                    <p>
                        메인 페이지의 메인 배너는 브랜드의 아이덴티티를
                        효과적으로 보여줄 수 있도록<br /><strong
                            >넓은 영역의 이미지</strong
                        >와 <strong>큰 사이즈의 텍스트</strong>를 사용했습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <img
                            src="@/assets/images/reference/grandcasa/main01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile">
                        <img
                            src="@/assets/images/reference/grandcasa/main02.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail dinfo">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DETAIL</h6>
                    <h1>상품 상세 소개</h1>
                    <p>
                        <strong>상품 상세 소개 페이지</strong>는 많은 양의
                        텍스트를 정보별로 구분을 짓는 게 중요했으며 특별히
                        강조할 부분은 폰트의 색상을 다르게 하여 주목성을
                        높였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <img
                            src="@/assets/images/reference/grandcasa/detail01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="web">
                        <img
                            src="@/assets/images/reference/grandcasa/detail02.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail booking">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>BOOKING</h6>
                    <h1>예약페이지</h1>
                    <p>
                        예약 페이지는 <strong>2단계</strong>로 나누어져, 제일
                        먼저 <strong>날짜, 객실, 인원수를</strong> 검색하면 그에
                        맞는 상품 리스트들을 볼 수 있습니다. 예약폼 입력 시 좌,
                        우 레이아웃으로 배치했으며, 오른쪽에는 선택한 상품의
                        정보가 고정되어 있어 스크롤을 내리지 않고도 사용자가
                        선택한 상품을 쉽게 확인할 수 있습니다
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="web">
                        <img
                            src="@/assets/images/reference/grandcasa/booking01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="image_right">
                        <div class="web">
                            <img
                                src="@/assets/images/reference/grandcasa/booking02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/grandcasa/responsive01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div>
                            <img
                                src="@/assets/images/reference/grandcasa/responsive02.jpg"
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/images/reference/grandcasa/responsive03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        신선함과 깨끗함은 <strong>블루컬러</strong>로, 생동감과
                        에너지는<strong>옐로우 오렌지 컬러</strong>로
                        표현했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Brown</p>
                        <p>R 137 G 107 B 84</p>
                        <p>#896B54</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Light Beige</p>
                        <p>R 253 G 249 B 241</p>
                        <p>#FDF9F1</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        주요 국문과 영문은 <strong>Noto Sans KR</strong>를
                        사용했으며, 비주얼적으로 사용한 영문폰트는
                        <strong>Nuosu SIL</strong>를 사용해 심미성을 높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Nuosu SIL<span>|</span><span>비주얼 영문</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nuosu+SIL&display=swap");
.reference.grandcasa .image_shadow {
    border-radius: 0;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.grandcasa .mockup_wrap > div:not(:last-child) {
    margin-bottom: 5rem;
}
.reference.grandcasa .main_banner {
    background-image: url("~@/assets/images/reference/grandcasa/img_mainBanner.jpg");
}
.reference.grandcasa .main {
    position: relative;
    background: linear-gradient(
            to top,
            var(--color-bg-white),
            var(--color-bg-white) 55%,
            transparent
        ),
        url("~@/assets/images/reference/grandcasa/banner.jpg") no-repeat center
            top/cover;
}
.reference.grandcasa .main .intro_wrap h6 {
    color: var(--color-font-light);
}
.reference.grandcasa .booking {
    background: url("~@/assets/images/reference/grandcasa/booking_bg.png")
        no-repeat right center/contain;
    background-size: 65rem;
    background-color: rgba(255, 255, 255, 0.5);
    background-blend-mode: color;
}
.reference.grandcasa .colorType_01 .box:nth-child(1) {
    background: #896b54;
}
.reference.grandcasa .colorType_01 .box:nth-child(2) {
    background: #fdf9f1;
    color: #896b54;
}
.reference.grandcasa .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.grandcasa .fontType_01 .box_02 {
    font-family: "Nuosu SIL", serif;
}
.reference.grandcasa .pages {
    background: linear-gradient(
            to top,
            var(--color-bg-white),
            var(--color-bg-white) 10%,
            transparent 20%
        ),
        url("~@/assets/images/reference/grandcasa/pages.png") no-repeat center
            bottom/cover;
}
.reference.grandcasa .pages::before {
    background: none;
}
</style>
