<template>
    <div class="c_content_container reference tonpay">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/tonpay/logo.svg"
                            alt="티온페이"
                        />
                    </div>
                    <h1 class="title">티온페이<br />사이트 개발</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱, 개발</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>
                                주식회사 티온은 온라인/오프라인 결제 분야
                                전문가를 중심으로<Br />편리하고 낮은 비용으로
                                이용하실수 있도록 서비스를 제공하고 있습니다.
                            </p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="https://www.t-onpay.com/" target="_blank"
                            >웹사이트 바로가기</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section detail overview">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>OVERVIEW</h6>
                    <h1>
                        모든 결제에 대한 솔루션과<br />
                        기타 부가서비스까지 한번에 해결
                    </h1>
                    <p>
                        주식회사 티온은
                        <strong>온라인/오프라인 결제 분야 전문가</strong>
                        중심으로 구성된 인력을 바탕으로 다양한 고객분들에게 기존
                        결제시스템의 불편함을 해소해드릴 뿐 아니라
                        <strong>편리하고 낮은 비용</strong>으로 이용하실수
                        있도록 서비스를 제공하고 있습니다. 보다 더 나은 결제
                        솔루션을 제공하는 티온페이는 고객님의 사업성공을 가장
                        가치에 둡니다.
                    </p>
                </div>
                <!--text_wrap 제외하고 web이랑 mobile이랑 한 이미지로 만들어서 centered 하는게 반응형 잡기에도 편할 것 같아요-->
                <!--div class="mockup_basic">
                    <div class="web img_shadow">
                        <div class="img_wrap">
                            <img
                                src="@/assets/images/reference/tonpay/overview01.png"
                                alt=""
                            />
                        </div>
                        <div class="text_wrap">
                            <h1>T-ONPAY WEB | 메인화면</h1>
                        </div>
                    </div>
                    <div class="mobile img_shadow">
                        <div class="img_wrap">
                            <img
                                src="@/assets/images/reference/tonpay/overview02.png"
                                alt=""
                            />
                        </div>
                        <div class="text_wrap">
                            <h1>T-ONPAY MOBILE | 메인화면</h1>
                        </div>
                    </div>
                </div-->
                <div class="image_centered">
                    <img
                        src="@/assets/images/reference/tonpay/overview.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section detail concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>입체적인 사이트</h1>
                    <p>
                        다양하고 간편한 결제를 제공하는 티온페이의 장점들을
                        깔끔한 레이아웃으로 보여주면서,
                        <strong>사용자들의 접근</strong>을 용이하게 만들고,
                        쉐도우 효과를 주어 <strong>입체적인 사이트</strong>의
                        분위기를 만들어냈습니다.<br />
                        티온페이의 로고스타일에 맞춰 전반적으로 완만한
                        라운드형으로 디자인했으며, 티온페이의 서비스나
                        프로세스를 직관적으로 보여드리기 위해 그래픽 위주의
                        이미지와 아이콘 사용으로 밝으면서
                        <strong>쉽게 이해</strong>할수 있도록 만들었습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_left">
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/tonpay/concept01.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="image_right">
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/tonpay/concept02.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="mockup_basic">
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/tonpay/concept03.png"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/tonpay/concept04.png"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/tonpay/concept05.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle_bg"></div>
            <div class="dot_bg"></div>
            <div class="circle_bg"></div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        purple컬러와 sky blue컬러 조합으로 활기차면서 생동감있는
                        사이트의 분위기를 표현했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Purple</p>
                        <p>R 90 G 61 B 149</p>
                        <p>#5A3D95</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Sky Blue</p>
                        <p>R 128 G 169 B 235</p>
                        <p>#80A9EB</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        서비스 고유의 시각적 특징을 반영한 아이콘을 적용하여<br />
                        사용자들이 서비스를 쉽게 이해할수 있도록 했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/tonpay/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/tonpay/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/tonpay/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/tonpay/icon04.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/tonpay/icon05.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/tonpay/icon06.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section services">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>SERVICES</h6>
                    <h1>다양한 서비스 정보의 안내</h1>
                    <p>
                        티온에서 다양하게 제공되는 서비스 정보를 사용자들이 쉽게
                        이해할수있도록 실제 보여지는 화면의 이미지와 서비스명에
                        맞는 결제방법들의 이미지를 적절하게 넣어 디자인
                        하였습니다.
                    </p>
                </div>
                <div class="roundType">
                    <div class="left_big">
                        <div class="inner_shadow">
                            <div class="img_wrap">
                                <img
                                    src="@/assets/images/reference/tonpay/service01.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="right_wrap">
                        <div class="inner_shadow">
                            <div class="img_wrap">
                                <img
                                    src="@/assets/images/reference/tonpay/service02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="inner_shadow">
                            <div class="img_wrap">
                                <img
                                    src="@/assets/images/reference/tonpay/service03.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="inner_shadow">
                            <div class="img_wrap">
                                <img
                                    src="@/assets/images/reference/tonpay/service04.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section function">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>FUNCTION</h6>
                    <h1>간단한 가입신청</h1>
                    <p>
                        <strong>간단한 가입신청</strong>으로 티온페이의 편리한
                        서비스를 이용하실 수 있습니다.
                    </p>
                </div>
                <div class="image_centered image_shadow">
                    <img
                        src="@/assets/images/reference/tonpay/function.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.tonpay .main_banner {
    background-image: url("~@/assets/images/reference/tonpay/img_mainBanner.jpg");
}
.reference.tonpay .concept {
    position: relative;
    background: url("~@/assets/images/reference/tonpay/concept_bg.png")
        no-repeat center top/cover;
    overflow: hidden;
}
.reference.tonpay .concept .mockup_wrap > div + div {
    margin-top: 4rem;
}
.reference.tonpay .concept .mockup_wrap .mockup_basic > div:nth-of-type(2) {
    margin-top: 3rem;
}
.reference.tonpay .concept .mockup_wrap .mockup_basic > div:nth-of-type(3) {
    margin-top: 6rem;
}
.reference.tonpay .image_shadow {
    border-radius: 2rem;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.tonpay .concept .circle_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 90rem;
    height: 90rem;
    background: url("~@/assets/images/reference/tonpay/circle_bg.svg") no-repeat
        center top/contain;
}
.reference.tonpay .concept .circle_bg:nth-of-type(4) {
    top: unset;
    left: unset;
    bottom: 0;
    right: 0;
}
.reference.tonpay .concept .dot_bg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 40rem;
    height: 40rem;
    background: url("~@/assets/images/reference/tonpay/dot_bg.svg") no-repeat
        center top/contain;
}
.reference.tonpay .colorType_01 .box {
    border-radius: 3rem;
}
.reference.tonpay .colorType_01 .box:nth-child(1) {
    background: #5a3d95;
}
.reference.tonpay .colorType_01 .box:nth-child(2) {
    background: #80a9eb;
}
.reference.tonpay .function {
    position: relative;
}
.reference.tonpay .function::before {
    content: "";
    position: absolute;
    top: 40rem;
    right: 0;
    display: block;
    width: 100%;
    height: 50rem;
    background: url("~@/assets/images/reference/tonpay/function_bg.png")
        no-repeat right top/ contain;
    opacity: 0.5;
}
.reference.tonpay .pages {
    background: url("~@/assets/images/reference/tonpay/pages.jpg") no-repeat
        center/cover;
}
@media screen and (max-width: 768px) {
    .reference.tonpay .concept .circle_bg,
    .reference.tonpay .concept .dot_bg {
        display: none;
    }
    .reference.tonpay .detail .mockup_basic .right {
        width: 30%;
        min-width: 20rem;
        margin: 0 auto;
    }
}
</style>
