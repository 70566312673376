<template>
    <div class="c_content_container reference chezgabby">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/chezgabby/logo.svg"
                            alt="쉐가비"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />프렌차이즈 레스토랑</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>
                                프랜차이즈 레스토랑을 타켓으로한 템플릿 사이트
                                입니다.
                            </p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section bannerimg">
            <div class="pt_inner">
                <img
                    src="@/assets/images/reference/chezgabby/main.png"
                    alt=""
                />
            </div>
        </section>

        <section class="pt_section concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>절제된 고급스러움</h1>
                    <p>
                        팬시한 프렌치 프랜차이즈 레스토랑을 컨셉으로 만든 이
                        템플릿은 전반적으로 심플하면서 절제된 고급스러운 느낌을
                        줍니다. 심플한 레이아웃으로 유저들이 정보를 습득하기
                        쉽도록 했습니다.메인컬러는 머스타드로 정하여 밝은
                        분위기를 주며 또한, 음식이 먹음직스러워 보이도록
                        해줍니다. 머스타드컬러가 가지고 있지 않은 묵직한
                        분위기와 고급스러운 분위기를 위해 서브컬러는 블랙을
                        선택했습니다.
                    </p>
                </div>
                <div class="pointType">
                    <div class="centered">
                        <div class="depth_01">
                            <div class="depth_02">
                                <h1>Fresh</h1>
                            </div>
                        </div>
                    </div>
                    <div class="flexed_wrap">
                        <div class="left">
                            <div class="inner_shadow">
                                <p>Fancy</p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="inner_shadow">
                                <p>Clear</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail menu">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MENU</h6>
                    <h1>절제된 레이아웃</h1>
                    <p>
                        음식 메뉴 정보에 집중할 수 있도록 너무 많은 장식요소들은
                        절제하고 심플하게 필요한 정보만으로 구성했습니다.
                        코스요리 소개시에는 직관적인 아이콘을 사용하여
                        유저들에게 정보를 쉽게 전달할 수 있습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="mockup_basic">
                        <div class="mobile">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/chezgabby/menu_top01.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="web">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/chezgabby/menu_top02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mockup_basic">
                        <div class="mobile">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/chezgabby/menu_bottom03.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="web">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/chezgabby/menu_bottom02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section about">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>ABOUT</h6>
                    <h1>카드형 레이아웃</h1>
                    <p>
                        카드 형태의 레이아웃으로 각 컨텐츠의 영역을 구분지어
                        정보를 습득하기 쉽도록 했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="mockup_basic">
                        <div class="web">
                            <div class="image_inner image_shadow">
                                <img
                                    src="@/assets/images/reference/chezgabby/about01.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="mobile">
                            <div class="image_inner image_shadow">
                                <img
                                    src="@/assets/images/reference/chezgabby/about02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mockup_basic">
                        <div class="mobile">
                            <div class="image_inner image_shadow">
                                <img
                                    src="@/assets/images/reference/chezgabby/about04.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="web">
                            <div class="image_inner image_shadow">
                                <img
                                    src="@/assets/images/reference/chezgabby/about03.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        메인컬러 라인아이콘을 사용하여 사용자들이 컨텐츠를<br />좀
                        더 직관적으로 쉽게 이해할 수 있도록 했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/chezgabby/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/chezgabby/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/chezgabby/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/chezgabby/icon04.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        메인컬러는 <strong>머스타드로</strong> 정하여 밝은
                        분위기를 주며 또한, 음식이 먹음직스러워 보이도록
                        해줍니다. 묵직한 분위기와 고급스러운 분위기를 위해
                        서브컬러는 <strong>블랙</strong>을 선택했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Mustard</p>
                        <p>R 37 G 96 B 96</p>
                        <p>#F69A0B</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Black</p>
                        <p>R 0 G 0 B 7</p>
                        <p>#131313</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>Noto Sans KR</strong>, 영문과 숫자는
                        <strong>Roboto</strong>로 사용해 가독성을 높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Roboto<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.chezgabby .main_banner {
    background-image: url("~@/assets/images/reference/chezgabby/img_mainBanner.jpg");
}
.reference.chezgabby .bannerimg {
    padding-bottom: 0;
}
.reference.chezgabby .concept .pointType div {
    font-size: var(--font-size-x-large);
    font-weight: var(--font-w-bold);
    box-shadow: none;
}
.reference.chezgabby .concept .pointType .centered {
    background: #f69a0b;
    color: #fff;
}
.reference.chezgabby .concept .pointType .flexed_wrap .left .inner_shadow {
    background: #131313;
    color: #fff;
}
.reference.chezgabby .concept .pointType .flexed_wrap .right .inner_shadow {
    border: 0.1rem solid var(--color-border-mid);
}
.reference.chezgabby .main_pg {
    position: relative;
    background: url("~@/assets/images/reference/iptk/main_bg.jpg") no-repeat
        center top/cover;
    background-size: auto 80rem;
    overflow: hidden;
    color: #fff;
}
.reference.chezgabby .main_pg .tit {
    color: #fff;
}
.reference.chezgabby .menu {
    background: #f4f4f4;
}
.reference.chezgabby .mockup_wrap .mockup_basic:not(:last-child) {
    margin-bottom: 5rem;
}
.reference.chezgabby .iconType_01 .icon_wrap div {
    width: calc((100% - 15rem) / 4);
}
.reference.chezgabby .iconType_01 .icon_wrap div img {
    max-width: 15rem;
    max-height: 15rem;
}
.reference.chezgabby .colorType_01 .box:nth-child(1) {
    background: #f69a0b;
}
.reference.chezgabby .colorType_01 .box:nth-child(2) {
    background: #131313;
}
.reference.chezgabby .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.chezgabby .fontType_01 .box_02 {
    font-family: "Roboto", sans-serif;
}
.reference.chezgabby .pages {
    background: url("~@/assets/images/reference/chezgabby/pages.jpg") no-repeat
        center/cover;
}
.reference.chezgabby .pages::before {
    background: none;
}
</style>
