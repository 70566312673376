<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
            <path class="fill_dark" d="M32.4,34.6l-9.6-9.6c-2.1,1.7-4.9,2.8-7.6,3.1c-2.9,0.3-7.2-0.1-11.1-4.1c-2.6-2.7-4.1-6.2-4-10
	s1.5-7.2,4.2-9.9c5.4-5.3,14.2-5.3,19.6,0c0,0,0,0,0,0c5.1,5.1,5.4,13.2,1,18.7l9.6,9.6L32.4,34.6z M14.1,3.1c-2.8,0-5.6,1-7.7,3.1
	C2.1,10.5,2,17.5,6.2,21.8c3.1,3.1,6.4,3.5,8.7,3.2c3-0.3,5.6-1.9,6.9-3.2c4.3-4.3,4.3-11.3,0-15.6C19.7,4.2,16.9,3.1,14.1,3.1z"/>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
