<template>
    <div class="c_content_container reference nuovo">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/nuovoseoul/logo.svg"
                            alt="누오보서울"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />전시회관 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>전시회관을 타켓으로한 템플릿 사이트 입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>작품에 집중할수 있도록</h1>
                    <p>
                        작품들의 색상이 다양하기 때문에 메인컬러는 블랙 색상을
                        선택했으며,<br />컨텐츠간의 간격을 여유롭게 두어
                        컨텐츠에만 온전히 집중할 수 있도록 구성했습니다.
                    </p>
                </div>
                <div>
                    <img
                        src="@/assets/images/reference/nuovoseoul/concept.jpg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>메인페이지</h1>
                    <p>
                        메인페이지의 메인 배너는 전시중인 포스터를 이미지로 하여
                        상황에 따라 <strong>유연하게 변화</strong> 할 수
                        있습니다. 현재 진행중인 전시리스트, 작가의 소장품들
                        그리고 공지사항과 이벤트까지 메인페이지에 노출시켜
                        유저들이 찾기 쉽게 구성하였습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_centered">
                        <img
                            src="@/assets/images/reference/nuovoseoul/main01.png"
                            alt=""
                        />
                    </div>
                    <div class="image_centered">
                        <img
                            src="@/assets/images/reference/nuovoseoul/main02.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section booking">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>BOOKING</h6>
                    <h1>누구나 쉽고 빠르게</h1>
                    <p>
                        사용자들이 온라인에서도 쉽고 빠르게 예매할 수 있도록
                        레이아웃을 구성했습니다.<br />
                        회원이 아니여도 예약할 수 있도록
                        <strong>비회원 로그인</strong>과
                        <strong>예매페이지</strong>를 만들었습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <div>
                            <img
                                src="@/assets/images/reference/nuovoseoul/booking01.jpg"
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/images/reference/nuovoseoul/booking02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div>
                            <img
                                src="@/assets/images/reference/nuovoseoul/booking03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="typo_bg">
                    <img
                        src="@/assets/images/reference/nuovoseoul/booking_typogp.svg"
                        alt=""
                    />
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <div>
                            <img
                                src="@/assets/images/reference/nuovoseoul/booking04.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="mobile">
                        <div>
                            <img
                                src="@/assets/images/reference/nuovoseoul/booking05.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section exhibitions">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>EXHIBITIONS</h6>
                    <h1>전시 상세 소개</h1>
                    <p>
                        여유로운 간격으로 각 컨텐츠간의 구분을 명확하게하여
                        사용자들에게 작품에 대한 <strong>정보 전달</strong>이 잘
                        될 수 있도록 했습니다. 또한, 작품의 큰 사이즈의 이미지와
                        주변의 여백을 넓게 하여 온전히 작품이 돋보일 수 있도록
                        했습니다.
                    </p>
                </div>
                <div class="image_centered">
                    <img
                        src="@/assets/images/reference/nuovoseoul/exhibitions.jpg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section search">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>SERACH</h6>
                    <h1>검색</h1>
                    <p>
                        원하시는 전시회를 쉽게 찾으실 수 있도록 헤더에 직관적인
                        돋보기 아이콘을 두어<br />어떤 페이지에서든 편리한
                        검색이 가능합니다.
                    </p>
                </div>
                <div class="image_centered image_shadow">
                    <img
                        src="@/assets/images/reference/nuovoseoul/search.jpg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/nuovoseoul/responsive01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/nuovoseoul/responsive02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/nuovoseoul/responsive03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        사용자들의 정보의 이해도를 높이기 위해 직관적인 라인
                        아이콘을 사용했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/nuovoseoul/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/nuovoseoul/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/nuovoseoul/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/nuovoseoul/icon04.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/nuovoseoul/icon05.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/nuovoseoul/icon06.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        전시회관의 세련된 무드와 작품의 컨텐츠가 색상이
                        있기때문에<br />
                        <strong>블랙</strong>과 <strong>화이트</strong> 기본
                        무채색을 사용하여 복잡하지 않게 정돈된 느낌을
                        주었습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Black</p>
                        <p>R 15 G 15 B 15</p>
                        <p>#0F0F0F</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>White</p>
                        <p>R 255 G 255 B 255</p>
                        <p>#FFFFFF</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>Noto Sans KR</strong>, 영문과 숫자는
                        <strong>Roboto</strong>를 사용해 가독성과 심미성을
                        높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Roboto<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.nuovo .image_shadow {
    border-radius: 0;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.nuovo .main_banner {
    background-image: url("~@/assets/images/reference/nuovoseoul/img_mainBanner.jpg");
}
.reference.nuovo .main {
    background: #f4f4f4;
    padding-bottom: unset;
}
.reference.nuovo .main .mockup_wrap .image_centered:nth-child(1) {
    position: relative;
}
.reference.nuovo .main .mockup_wrap .image_centered:nth-child(1):after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
}
.reference.nuovo .booking .typo_bg {
    margin: 8rem 0;
}
.reference.nuovo .booking .mockup_basic .web > div {
    border: 0.1rem solid var(--color-border-mid);
}
.reference.nuovo .exhibitions {
    position: relative;
    background: url("~@/assets/images/reference/nuovoseoul/exhibitions_bg.jpg")
        no-repeat center top/cover;
    background-size: 100% 80rem;
    overflow: hidden;
    color: #fff;
}
.reference.nuovo .search {
    background: url("~@/assets/images/reference/nuovoseoul/search_bg.png")
        no-repeat center 30rem / contain;
}
.reference.nuovo .responsive .mockup_basic {
    margin-top: 6rem;
}
.reference.nuovo .colorType_01 .box:nth-child(1) {
    background: #0f0f0f;
}
.reference.nuovo .colorType_01 .box:nth-child(2) {
    background: #fff;
    color: #333;
    border: 0.1rem solid var(--color-border-mid);
}
.reference.nuovo .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.nuovo .fontType_01 .box_02 {
    font-family: "Roboto", sans-serif;
}
.reference.nuovo .pages {
    background: url("~@/assets/images/reference/nuovoseoul/pages.jpg") no-repeat
        center/cover;
}
.reference.nuovo .pages::before {
    background: none;
}
</style>
