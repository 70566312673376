//포트폴리오 콘텐츠 데이터는 날짜 순(최신 순)으로 정렬되어 있어야 합니다.
//이미지의 경로는 "@/assets/images/reference/{img}/img_mainBanner.{imgType}"로 설정되어야 합니다.
export const referenceContents = [
  {
    year: 2023,
    month: "May",
    date: 9,
    img: "withfounder",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Withfounder", "Dsc About Platform Web"],
    text: "Dsc About Withfounder Pf",
    url: "/reference/withfounder",
  },
  {
    year: 2023,
    month: "Feb",
    date: 9,
    img: "giveheart",
    imgType: "jpg",
    tag: "App",
    title: ["Giveheart", "Dsc About Platform Web"],
    text: "Dsc About Giveheart Pf",
    url: "/reference/giveheart",
  },
  {
    year: 2022,
    month: "Nov",
    date: 15,
    img: "humanvalue",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Human Value", "Dsc About Pr Web"],
    text: "Dsc About Hv Pf",
    url: "/reference/humanvalue",
  },
  {
    year: 2022,
    month: "Aug",
    date: 11,
    img: "vinacts",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Vinacts", "Dsc About Pr Web"],
    text: "Dsc About Vinacts Pf",
    url: "/reference/vinacts",
  },
  {
    year: 2022,
    month: "Jun",
    date: 10,
    img: "newflex",
    imgType: "png",
    tag: "Responsive Web",
    title: ["New Flex", "Dsc About Responsive Shpmall"],
    text: "Dsc About Newflex Pf",
    url: "/reference/newflex",
  },
  {
    year: 2022,
    month: "May",
    date: 10,
    img: "papier",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Papier", "Dsc About Pr Web"],
    text: "Dsc About Papier Pf",
    url: "/reference/papier",
  },
  {
    year: 2022,
    month: "Apr",
    date: 1,
    img: "eum",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Eum", "Dsc About Pr Web"],
    text: "Dsc About Eum Pf",
    url: "/reference/eum",
  },
  {
    year: 2022,
    month: "Mar",
    date: 10,
    img: "iptk",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Iptk", "Dsc About Pr Web"],
    text: "Dsc About Iptk Pf",
    url: "/reference/iptk",
  },
  {
    year: 2022,
    month: "Jan",
    date: 1,
    img: "tonpay",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Ton", "Dsc About Pr Web"],
    text: "Dsc About Ton Pf",
    url: "/reference/tonpay",
  },
  {
    year: 2021,
    month: "Nov",
    date: 19,
    img: "mpc",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Mpc", "Dsc About Pr Web"],
    text: "Dsc About Mpc Pf",
    url: "/reference/mpc",
  },

  {
    year: 2021,
    month: "Oct",
    date: 1,
    img: "chezgabby",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Restaurant"],
    text: "Dsc About Template Pf",
    url: "/reference/chezgabby",
  },
  {
    year: 2021,
    month: "Oct",
    date: 10,
    img: "hanvill",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Apt"],
    text: "Dsc About Template Pf",
    url: "/reference/hanvill",
  },
  {
    year: 2024,
    month: "Oct",
    date: 20,
    img: "eieio",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Shopping Mall"],
    text: "Dsc About Template Pf",
    url: "/reference/eieio",
  },
  {
    year: 2021,
    month: "Sep",
    date: 25,
    img: "nuovoseoul",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Exhibition"],
    text: "Dsc About Template Pf",
    url: "/reference/nuovoseoul",
  },
  {
    year: 2021,
    month: "Sep",
    date: 30,
    img: "travelto",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Travel"],
    text: "Dsc About Template Pf",
    url: "/reference/travelto",
  },
  {
    year: 2021,
    month: "Sep",
    date: 20,
    img: "bellaclinic",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Hospital"],
    text: "Dsc About Template Pf",
    url: "/reference/bellaclinic",
  },
  {
    year: 2021,
    month: "Aug",
    date: 10,
    img: "freshmart",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Grocery"],
    text: "Dsc About Template Pf",
    url: "/reference/freshmart",
  },
  {
    year: 2021,
    month: "Aug",
    date: 10,
    img: "sungwon",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Univ"],
    text: "Dsc About Template Pf",
    url: "/reference/sungwon",
  },
  {
    year: 2021,
    month: "Aug",
    date: 10,
    img: "urplace",
    imgType: "jpg",
    tag: "Responsive Web",
    title: ["Website Template", "Cafe"],
    text: "Dsc About Template Pf",
    url: "/reference/urplace",
  },
];
