<template>
    <div class="c_content_container reference eieio">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/eieio/logo.svg"
                            alt="eieio"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />쇼핑몰 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>쇼핑몰을 타켓으로한 템플릿 사이트 입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>세련된 무드</h1>
                    <p>
                        <strong>세련</strong>되면서 <strong>트렌디함</strong>을
                        컨셉으로 잡았으며, 메인 페이지 각 콘텐츠 별 제목에는
                        추가적으로 다른 폰트를 사용해 <strong>심미성</strong>을
                        높였습니다. 또한 많은 상품들을 보여줘야 되는 쇼핑몰
                        사이트 특성상 같은 레이아웃을 반복한다면 지루할 수
                        있기에 각 콘텐츠마다 개성을 살려 디자 인했습니다.
                    </p>
                </div>
                <div class="img_wrap">
                    <img
                        src="@/assets/images/reference/eieio/concept.svg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>메인페이지</h1>
                    <p>
                        다양한 상품들을 소개할 수 있도록 개성 있는 레이아웃으로
                        구성했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="mockup_basic">
                        <div class="web">
                            <img
                                src="@/assets/images/reference/eieio/main.jpg"
                                alt=""
                            />
                        </div>
                        <div class="mobile">
                            <div class="">
                                <img
                                    src="@/assets/images/reference/eieio/main_m01.jpg"
                                    alt=""
                                />
                            </div>
                            <div class="">
                                <img
                                    src="@/assets/images/reference/eieio/main_m02.jpg"
                                    alt=""
                                />
                            </div>
                            <div class="">
                                <img
                                    src="@/assets/images/reference/eieio/main_m03.jpg"
                                    alt=""
                                />
                            </div>
                            <div class="">
                                <img
                                    src="@/assets/images/reference/eieio/main_m04.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section search">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>SEARCH</h6>
                    <h1>검색</h1>
                    <p>
                        <strong>검색 기능 강화</strong>로 고객이 원하는 상품만
                        골라서 볼 수 있으며,<br />구매 단계까지 갈 수 있도록
                        도와줍니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/search01.jpg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/search02.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section order">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>ORDER</h6>
                    <h1>주문페이지</h1>
                    <p>
                        쉽고 빠른 주문을 위해 구조와
                        <strong>프로세스를 간소화</strong> 하였습니다.
                        장바구니에서는 고객의 취향에 맞는
                        <strong>상품을 추천</strong>하는 리스트가 노출되도록
                        했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="mockup_basic">
                        <div class="web">
                            <img
                                src="@/assets/images/reference/eieio/order_w01.jpg"
                                alt=""
                            />
                        </div>
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/eieio/order_m01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="mockup_basic">
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/eieio/order_m02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="web">
                            <img
                                src="@/assets/images/reference/eieio/order_w02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section modal">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MODAL</h6>
                    <h1>모달창</h1>
                    <p>
                        상품리뷰같은 컨텐츠의 양이 많은경우 모달창을 적용하여
                        <strong>스크롤링을 최소화</strong> 하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <img
                            src="@/assets/images/reference/eieio/modal_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile">
                        <img
                            src="@/assets/images/reference/eieio/modal_m.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/responsive01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div>
                            <img
                                src="@/assets/images/reference/eieio/responsive02.jpg"
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                src="@/assets/images/reference/eieio/responsive03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        사용자들의 정보의 이해도를 높이기 위해 직관적인 라인
                        아이콘을 사용했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/icon04.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/icon05.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/eieio/icon06.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        메인컬러는<strong>레드 컬러</strong>를 사용하여 생동감을
                        주었으며, 서브컬러는 <strong>다크그레이 컬러</strong>를
                        사용하여 세련되면서 고급스러움을 표현했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Red</p>
                        <p>R 197 G 23 B 0</p>
                        <p>#C51700</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Dark Gray</p>
                        <p>R 51 G 51 B 51</p>
                        <p>#333333</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>Noto Sans KR</strong>를 사용했으며,
                        영문,숫자는 <strong>Roboto</strong>를 사용해 가독성과
                        심미성을 높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Roboto<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;700&display=swap");
.reference.eieio .image_shadow {
    border-radius: 0;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.eieio .mockup_wrap > div:not(:last-child) {
    margin-bottom: 5rem;
}
.reference.eieio .main_banner {
    background-image: url("~@/assets/images/reference/eieio/img_mainBanner.jpg");
}
.reference.eieio .concept .pt_inner {
    display: flex;
    gap: 5rem;
    flex-wrap: wrap;
}
.reference.eieio .concept .intro_wrap {
    width: calc(100% - 5rem - 30rem);
}
.reference.eieio .concept .img_wrap {
    width: 30rem;
}
.reference.eieio .main {
    background: url("~@/assets/images/reference/eieio/main_bg.jpg") no-repeat
        top center/cover;
    color: #fff;
}
.reference.eieio .modal {
    background: #3d3d3d;
    color: #fff;
}
.reference.eieio .modal .mockup_basic > div {
    border: 0.1rem solid #707070;
}
.reference.eieio .responsive .mockup_wrap img {
    border: 0.2rem solid #333;
}
.reference.eieio .colorType_01 .box:nth-child(1) {
    background: #c51700;
}
.reference.eieio .colorType_01 .box:nth-child(2) {
    background: #333333;
}
.reference.eieio .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.eieio .fontType_01 .box_02 {
    font-family: "Roboto", sans-serif;
}
.reference.eieio .pages {
    background: url("~@/assets/images/reference/eieio/pages.jpg") no-repeat
        center top/cover;
}
.reference.eieio .pages::before {
    background: none;
}
@media screen and (max-width: 768px) {
    .reference.eieio .concept .intro_wrap {
        width: 100%;
    }
    .reference.eieio .concept .img_wrap {
        margin: 0 auto;
    }
}
</style>
