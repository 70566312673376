<template>
    <div class="c_content_container reference urplace">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/urplace/logo.svg"
                            alt="유얼플레이스"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />카페 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>카페를 타켓으로한 템플릿 사이트 입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>따뜻한 마음</h1>
                    <p>
                        <strong>따뜻한 마음, 정식한 서비스</strong>를 가진
                        유얼플레이스의 아이덴티티를 담았습니다.
                    </p>
                </div>
                <div class="img_wrap">
                    <img
                        src="@/assets/images/reference/urplace/concept.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>메인페이지</h1>
                    <p>
                        메인 페이지에는 <strong>중요한 컨텐츠</strong>만
                        배치하여 첫화면에서 바로 찾기 쉽도록 구성하였습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/urplace/main_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/urplace/main_m01.jpg"
                                alt=""
                            />
                        </div>
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/urplace/main_m02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 menu">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MENU</h6>
                    <h1>메뉴</h1>
                    <p><strong>depth1</strong>으로만 이루어져 찾기 쉽습니다.</p>
                </div>
                <div class="mockup_basic">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/urplace/menu_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile image_shadow">
                        <img
                            src="@/assets/images/reference/urplace/menu_m.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 contents">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>BRAND</h6>
                    <h1>브랜드 소개</h1>
                    <p>
                        텍스트만으로 이루어진 콘텐츠는 자칫 지루하고 전달력이
                        떨어질 수 있습니다.<br />그런 점을 보완하기 위해 사용자
                        이해에 도움을 줄 이미지와 아이콘을<br />사용하여
                        전달력을 높였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/urplace/brand_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile image_shadow">
                        <img
                            src="@/assets/images/reference/urplace/brand_m.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/urplace/responsive01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/urplace/responsive02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/urplace/responsive03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 detail iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        사용자들의 정보의 이해도를 높이기 위해 직관적인 아이콘을
                        사용했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/urplace/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/urplace/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/urplace/icon03.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        <strong>밀크 브라운</strong>은 따뜻한 커피한잔을
                        연상시키는 컬러이면서 소프트한 무드도 가지고 있기에<br />유얼플레이스의
                        아이덴티티를 잘 표현해줄 수 있는 컬러입니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Milk Brown</p>
                        <p>R 158 G 96 B 68</p>
                        <p>#9e6044</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>Noto Sans KR</strong>, 영문과 숫자는
                        <strong>Roboto</strong>로 사용해 가독성을 높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Roboto<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.urplace .image_shadow {
    border-radius: 0;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.urplace .mockup_wrap > div:not(:last-child) {
    margin-bottom: 5rem;
}
.reference.urplace .main_banner {
    background-image: url("~@/assets/images/reference/urplace/img_mainBanner.jpg");
}
.reference.urplace .concept {
    background: linear-gradient(
        #9e6044,
        var(--color-bg-white) 90%,
        transparent
    );
}
.reference.urplace .concept .intro_wrap h6 {
    color: #fff;
}
.reference.urplace .concept .pt_inner {
    display: flex;
    gap: 5rem;
    flex-wrap: wrap;
}
.reference.urplace .concept .intro_wrap {
    width: calc(100% - 5rem - 50rem);
}
.reference.urplace .concept .img_wrap {
    width: 50rem;
}
.reference.urplace .main .mockup_wrap .mockup_basic {
    justify-content: flex-end;
}
.reference.urplace .colorType_01 .box:nth-child(1) {
    width: 100%;
    background: #9e6044;
}
.reference.urplace .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.urplace .fontType_01 .box_02 {
    font-family: "Roboto", sans-serif;
}
.reference.urplace .pages {
    background: url("~@/assets/images/reference/urplace/pages.jpg") no-repeat
        center top/cover;
}
.reference.urplace .pages::before {
    background: none;
}
@media screen and (max-width: 768px) {
    .reference.urplace .concept .pt_inner .intro_wrap {
        width: 100%;
    }
    .reference.urplace .concept .pt_inner .img_wrap {
        margin: 0 auto;
    }
}
</style>