<template>
    <div class="c_content_container reference withfounder">
        <!--메인배너 START-->
        <div class="main_banner p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="icon_wrap">
                        <img src="@/assets/images/reference/withfounder/logo.svg" alt="" />
                    </div>
                    <h1 class="title">위드파운더<br />모바일 화면</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>23. 05. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱, 개발</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>
                              스타트업 회사들의 성장을 돕는 회사입니다.
                            </p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="https://www.withfounder.com/" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section overview">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Overview</h6>
                    <h1>부스터의 후원으로<br /><span>파운더의 성장을</span></h1>
                    <p>
                        위드파운더는 스타트업 창업을 계획하고 있는 예비창업자 스타트업의 창업자,<br/>
                        동업자가 올린 파운딩을 보고 <strong>부스터가 후원</strong>을 하여 <strong>파운더의 성장</strong>을 도울 수<br />
                        있도록 도움을 주는 회사입니다.
                    </p>
                </div>
                <div class="gridType">
                    <div class="col3">
                        <div class="inner shadow_inset-light">
                            <div class="image_wrap">
                                <img src="@/assets/images/reference/withfounder/illust_founder.svg" alt=""/>
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h1>부스터의 후원을 기다리는<br />스타트업의 창업자 파운더</h1>
                            <p>
                                파운더는 자사의 아이템을 파운딩 등록하여 부스터의 후원을 받을 수 있으며 부스터 프로에게 컨설팅 상담을 요청할 수 도 있습니다.
                            </p>
                        </div>
                    </div>
                    <div class="col3">
                        <div class="inner shadow_inset-light">
                            <div class="image_wrap">
                                <img src="@/assets/images/reference/withfounder/illust_booster.svg" alt="" />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h1>앞으로 소비자가<br />될 수 있는 부스터</h1>
                            <p>
                                부스터는 파운더가 성장하도록 후원하는 그룹입니다. 이 그룹은 후원자이자 앞으로 소비자가 될 수 있는 일반그룹입니다.
                            </p>
                        </div>
                    </div>
                    <div class="col3">
                        <div class="inner shadow_inset-light">
                            <div class="image_wrap">
                                <img src="@/assets/images/reference/withfounder/illust_boosterpro.svg" alt="" />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h1>파운더의 성장에 전문적 지식을<br/>줄 수 있는 부스터프로</h1>
                            <p>
                                파운더에게 전문 지식 컨설팅을 제공할수 있으며, 마음에 드는 상품을 캐시 후원할 수 도 있습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail detail_01 subType_01">
            <div class="subtitle_background">
                <div class="filled"></div>
                <div class="line"></div>
            </div>
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Founding</h6>
                    <h1>
                        파운딩 등록
                    </h1>
                    <p>
                        후원을 받을 아이템을 등록할 수 있으며, 파운딩 상세에는 파운더의 프로필, 아이템 설명, 후원포인트3가지 등 보여지며,
                        계정에 따라 리뷰또한 남기고 답변할 수 있도록 되어있습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/withfounder/founding01.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mobile">
                        <img
                            src="@/assets/images/reference/withfounder/founding02.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/withfounder/founding03.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail detail_01 subType_01">
            <div class="subtitle_background">
                <div class="filled"></div>
                <div class="line"></div>
            </div>
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Consulting</h6>
                    <h1>컨설팅 상담</h1>
                    <p>
                        파운더는 궁금한 사항이나 도움이 필요한 경우 컨설팅 상담을 통해 부스터 프로 유저에게 상담을 요청할 수 있습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img src="@/assets/images/reference/withfounder/consulting01.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="mobile">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img src="@/assets/images/reference/withfounder/consulting02.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail detail_01 subType_01">
            <div class="subtitle_background">
                <div class="filled"></div>
                <div class="line"></div>
            </div>
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Search</h6>
                    <h1>내가 원하는 검색을<br/>쉽고 빠르게</h1>
                    <p>
                        유저 종류와 분야 종류를 직접 선택하여 쉽고 빠르게 검색 할 수 있도록 하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img src="@/assets/images/reference/withfounder/search01.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="mobile">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img src="@/assets/images/reference/withfounder/search02.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        기본 메인컬러로는 에메랄드 색상이며 이색상은 파운더를 상징하는 색상이기도 합니다.
                        부스터 컬러로는 그레이쉬 블루, 부스터 프로는 다크퍼플로 지정하여 유저종류를 좀 더 구별하기 쉽도록 했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">FOUNDER: Main</p>
                        <p>Emerald</p>
                        <p>R 125 G 201 B 165</p>
                        <p>#6562A4</p>
                    </div>
                    <div class="box">
                        <p class="tit">BOOSTER</p>
                        <p>Grayish Blue</p>
                        <p>R 83 G 138 B 163</p>
                        <p>#f4f4f4</p>
                    </div>
                    <div class="box">
                        <p class="tit">BOOSTER PRO</p>
                        <p>Dark Purple</p>
                        <p>R 64 G 49 B 82</p>
                        <p>#403152</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문과 영문 둘다 공통으로 쓰기 적절한
                        <strong>Spoqa Han Sans Neo</strong> 폰트를 사용하여 가독성을 높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Spoqa Han Sans Neo<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Spoqa Han Sans Neo<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
@import url('//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css');
.reference.withfounder .pt_section + .pt_section {
    border-top: 2rem solid #f5f5f5;
}
.reference.withfounder .main_banner {
    background-image: url("~@/assets/images/reference/withfounder/img_mainBanner.jpg");
}
.reference .subType_01 .subtitle_background {
    top: 12rem;
}
.reference .main_banner .inner .title_wrap .icon_wrap {
    height: unset;
}
.reference.withfounder .overview .intro_wrap h1 span {
    color:#7dc9a5;
}
.reference.withfounder .gridType .col3:nth-child(1) .image_wrap {
    border: 3px solid #7dc9a5;
}
.reference.withfounder .gridType .col3:nth-child(1) .text_wrap h1 {
    border-color: #7dc9a5;
}
.reference.withfounder .gridType .col3:nth-child(2) .image_wrap {
    border: 3px solid #538AA3;
}
.reference.withfounder .gridType .col3:nth-child(2) .text_wrap h1 {
    border-color: #538AA3;
}
.reference.withfounder .gridType .col3:nth-child(3) .image_wrap {
    border: 3px solid #403152;
}
.reference.withfounder .gridType .col3:nth-child(3) .text_wrap h1 {
    border-color: #403152;
}
.reference.withfounder .gridType .inner .image_wrap img {
    object-fit: contain;
    width: 70%;
    height: 70%;
}
.reference.withfounder .fontType_01 {
    font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif';
}
.reference.withfounder .colorType_01 .box {
    border-radius: 3rem;
}
.reference.withfounder .colorType_01 .box:nth-child(1) {
    background: #7dc9a5;
}
.reference.withfounder .colorType_01 .box:nth-child(2) {
    background: #538AA3;
}
.reference.withfounder .colorType_01 .box:nth-child(3) {
    background: #403152;
}
</style>