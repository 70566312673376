<template>
    <div class="c_content_container reference bellaclinic">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/bellaclinic/logo.svg"
                            alt="벨라클리닉"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />병원 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>병원을 타켓으로한 템플릿 사이트 입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section subType_02 concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>벨라클리닉만의 특별함</h1>
                    <p>
                        메인배너를 크게하여 병원의 아이덴티티를 임팩트있게 보여줬으며,<br />메인컬러인 블루를 사용하여 전체적으로 투명하면서 깨끗한 느낌이 들도록 디자인했습니다.
                    </p>
                </div>
                <div class="img_wrap">
                    <img
                        src="@/assets/images/reference/bellaclinic/concept.jpg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>메인페이지</h1>
                    <p>
                        메인 페이지에는 <strong>중요한 컨텐츠</strong>만
                        배치하여 첫화면에서 바로 찾기 쉽도록 구성하였습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/bellaclinic/main_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/bellaclinic/main_m01.jpg"
                                alt=""
                            />
                        </div>
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/bellaclinic/main_m02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 menu">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MENU</h6>
                    <h1>메뉴</h1>
                    <p>웹에서는<strong>사이드퀵메뉴</strong>를 구성하여 사용자가 페이지를 좀 더 빠르고 쉽게 찾을 수 있습니다.</p>
                </div>
                <div class="mockup_basic">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/bellaclinic/menu_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile image_shadow">
                        <img
                            src="@/assets/images/reference/bellaclinic/menu_m.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>ABOUT</h6>
                    <h1>병원 소개</h1>
                    <p>
                        텍스트만으로 이루어진 콘텐츠는 자칫 지루하고 전달력이
                        떨어질 수 있습니다.<br />그런 점을 보완하기 위해 사용자
                        이해에 도움을 줄 이미지와 아이콘을<br />사용하여
                        전달력을 높였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/bellaclinic/aboutus_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile image_shadow">
                        <img
                            src="@/assets/images/reference/bellaclinic/aboutus_m.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 booking">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>BOOKING</h6>
                    <h1>예약페이지</h1>
                    <p>
                        사용자의 편의를 위한 온라인 예약 서비스를 제공합니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web image_shadow">
                        <img 
                            src="@/assets/images/reference/bellaclinic/booking_w.jpg" 
                            alt=""
                        />
                    </div>
                    <div class="mobile image_shadow">
                        <img
                            src="@/assets/images/reference/bellaclinic/booking_m.jpg" 
                            alt="" 
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_shadow">
                        <img  src="@/assets/images/reference/bellaclinic/clinic_w.jpg"
                            alt="" />
                    </div>
                    <div class="mockup_basic">
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/bellaclinic/clinic_t.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/bellaclinic/clinic_m.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 detail iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        사용자들의 정보의 이해도를 높이기 위해 직관적인 아이콘을
                        사용했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/bellaclinic/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/bellaclinic/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/bellaclinic/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/bellaclinic/icon04.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/bellaclinic/icon05.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/bellaclinic/icon06.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
        
        <section class="pt_section subType_02 colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        메인컬러는 <strong>블루</strong>로 정하여 병원의 청결함과 신뢰감을 표현했으며, 서브컬러로는 <strong>골드 브라운</strong> 을 사용하여 따뜻하면서도 고급스러운 느낌을 주었습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Blue</p>
                        <p>R 60 G 118 B 160</p>
                        <p>#3c76a0</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Gold Brown</p>
                        <p>R 168 G 125 B 69</p>
                        <p>#a87d45</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>Noto Sans KR</strong>, 영문과 숫자는
                        <strong>Roboto</strong>로 사용해 가독성을 높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Roboto<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.bellaclinic .image_shadow {
    border-radius: 0;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.bellaclinic .mockup_wrap > div:not(:last-child) {
    margin-bottom: 5rem;
}
.reference.bellaclinic .main_banner {
    background-image: url("~@/assets/images/reference/bellaclinic/img_mainBanner.jpg");
}
.reference.bellaclinic .concept .img_wrap {
    height: 50rem;
    overflow: hidden;
}
.reference.bellaclinic .main .mockup_wrap .mockup_basic {
    justify-content: flex-end;
}
.reference.bellaclinic .colorType_01 .box:nth-child(1) {
    background: #3c76a0;
}
.reference.bellaclinic .colorType_01 .box:nth-child(2) {
    background: #a87d45;
}
.reference.bellaclinic .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.bellaclinic .fontType_01 .box_02 {
    font-family: "Roboto", sans-serif;
}
.reference.bellaclinic .pages {
    background: url("~@/assets/images/reference/bellaclinic/pages.jpg") no-repeat
        center top/cover;
}
</style>