<template>
    <div class="c_content_container reference humanvalue">
        <!--메인배너 START-->
        <div class="main_banner p_center g_dark">
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/humanvalue/logo_humanvalue.svg"
                            alt=""
                        />
                    </div>
                    <h1 class="title">휴먼밸류<br />웹사이트 리뉴얼</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱, 개발</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>
                                휴먼밸류는 차별화된 교육으로 <br />금융전문가를
                                양성하는 기관입니다.
                            </p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="https://humanvalue.co.kr/" target="_blank"
                            >웹사이트 바로가기</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section challenge">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Challange</h6>
                    <h1>
                        금융전문가를 전반적으로<br />
                        교육하는 기관 휴먼밸류
                    </h1>
                    <p>
                        휴먼밸류는 차별화된 교육으로 금융전문가를 양성하는
                        기관으로서 20년 이상의 경력, 50개 이상의 고객사, 1,200개
                        이상의 교육프로그램으로 금융전문가를 더욱 경쟁력있게
                        양성하고 있습니다. 본 웹사이트에서는 간단한 회사소개와
                        함께 다양한 강사진들의 이력 및 커리큘럼을 제공하여
                        확인할 수 있도록 하였고, 다양한 교육프로그램의 상세한
                        정보를 제공하고 있습니다. Contact 페이지를 통해 사용자가
                        강의에 대한 궁금한 부분을 홈페이지 관리자에게 문의하여
                        소통할 수 있게 구축하였습니다.
                    </p>
                </div>
                <div class="pointType">
                    <div class="centered">
                        <div class="depth_01">
                            <h1>금융전문가<br />교육기관</h1>
                            <p>다양한 정보 제공</p>
                        </div>
                    </div>
                    <div class="flexed_wrap">
                        <div class="left">
                            <div class="point">
                                <div class="line"></div>
                                <div class="circle"></div>
                            </div>
                            <div class="inner_shadow">
                                <p>
                                    1,200개 이상의<Br /> 금융전문교육<br />
                                    프로그램 제공
                                </p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="inner_shadow">
                                <p>
                                    다양한 분야의<br />
                                    전문 강사진들 보유
                                </p>
                            </div>
                            <div class="point">
                                <div class="circle"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section solution">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Solution</h6>
                    <h1>
                        고급스러움과 신뢰감이<br />
                        사용자의 마음속으로
                    </h1>
                    <p>
                        홈페이지 리뉴얼의 가장 큰 핵심은 사용자에게 홈페이지를
                        통해 휴먼밸류 브랜드가 '금융전문가 교육기관'이라는
                        이미지를 얼마나 효과적으로 잘 전달할 수 있는가였습니다.
                        객관적인 지표가 될 수 있는 브랜드의 경력, 고객사의 수,
                        프로그램의 갯수, 연혁 등의 사실기반의 정보들을
                        부각시키고, 실사 위주의 이미지를 사용하여 신뢰감을
                        더하였으며, 중후한 블랙톤으로 브랜드의 무게감이
                        느껴지도록 표현했습니다.
                    </p>
                </div>
                <div class="textType">
                    <div class="content">
                        <h3>Brand Point</h3>
                        <p>
                            경력 등 핵심<br />
                            정보 강조
                        </p>
                    </div>
                    <div class="icon plus">
                        <img
                            src="@/assets/images/reference/humanvalue/icon_plus.svg"
                        />
                    </div>
                    <div class="content">
                        <h3>Brand Image</h3>
                        <p>
                            실사위주의 이미지를 이용하여<br />
                            전문적인 느낌 강조
                        </p>
                    </div>
                    <div class="icon plus">
                        <img
                            src="@/assets/images/reference/humanvalue/icon_plus.svg"
                        />
                    </div>
                    <div class="content">
                        <h3>Brand Color</h3>
                        <p>
                            블랙컬러로 고급스러움과<br />
                            신뢰감을 표현
                        </p>
                    </div>
                    <div class="icon equal">
                        <img
                            src="@/assets/images/reference/humanvalue/icon_equal.svg"
                        />
                    </div>
                    <div class="content">
                        <h3>Brand Position</h3>
                        <p>
                            금융전문가 교육기관<br />
                            휴먼밸류
                        </p>
                    </div>
                </div>
                <div class="mockup_basic">
                    <div class="mobile image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/humanvalue/img_mobile_01.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/humanvalue/img_web_01.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Company introduction</h6>
                    <h1>기업소개와 연혁</h1>
                    <p>
                        휴먼밸류의 CEO인사말, 연혁, 사업영역을 소개합니다. 실사
                        이미지/아이콘으로 각 섹션의 포인트를 직관적으로
                        표현합니다.<br />
                        브라우저 스크롤을 함에 따라 나타나는 모션을 통해 브랜드
                        정보를 지루하지 않게 전달합니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/humanvalue/img_mobile_02.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/humanvalue/img_web_02.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail bgColor">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Program Contents</h6>
                    <h1>단/장기 교육 프로그램</h1>
                    <p>
                        종류가 많은 프로그램 목록은 아이콘을 이용해
                        카테고리화하여 직관적으로 표현하였고 복잡해 보일 수 있는
                        프로그램 목록은 각 카테고리를 눌렀을 때만 팝업 형태로
                        노출하여 페이지의 복잡성을 최소화했습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/humanvalue/img_mobile_03_01.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/humanvalue/img_mobile_03_02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/humanvalue/img_web_03_01.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/humanvalue/img_web_03_02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>
<style scoped>
.reference.humanvalue .main_banner {
    background-image: url("~@/assets/images/reference/humanvalue/img_mainBanner.jpg");
}
.reference.humanvalue .pointType .centered {
    background-color: #eee;
    /* width: calc((100vw - 10rem - 6rem) / 2); */
    /* height: calc((100vw - 10rem - 6rem) / 2); */
}
.reference.humanvalue .pointType .centered .depth_01 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    width: calc(100% - 8rem);
    min-width: 14rem;
    height: calc(100% - 8rem);
    min-height: 14rem;
    border-radius: var(--border-radius-full);
    background-color: #fff;
    box-shadow: 2px 3px 10px #bbb;
}
.reference.humanvalue .pointType .centered h1 {
    font-size: var(--font-size-large);
    font-weight: var(--font-w-bold);
    line-height: 1.4;
}
.reference.humanvalue .pointType .centered p {
    color: #a6a6a6;
    /* margin-top: 1rem; */
}
.reference.humanvalue .pointType .flexed_wrap .inner_shadow {
    background-color: #111;
    color: #fff;
    box-shadow: none;
    font-size: Var(--font-size-mid);
}

.reference.humanvalue .solution {
    background-color: #f5f5f5;
}
.reference.humanvalue .solution .textType {
    margin-bottom: 10rem;
}
.reference.humanvalue .detail.bgColor {
    background-color: #f5f5f5;
}
</style>
