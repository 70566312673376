<template>
    <div class="c_content_container reference eum">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/eum/logo.svg"
                            alt="이음건설"
                        />
                    </div>
                    <h1 class="title">이음건설<br />웹사이트 구축</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>이음건설은 최고의 내진보강기술로 지진재해로부터<br />안전하게 지키는 건설회사입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="https://www.eumcon.com/" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section subType_02 challenge">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Challange</h6>
                    <h1>축적된 노하우와 최고의 기술력을 통해<Br />일류 제품을 생산하는 기업</h1>
                    <p>
                        구조설계 전문가 집단으로 최고의 기술력을 바탕으로 최상의 구조설계 엔지니어링을 제공합니다.<br />일반건축물 부터 초고층건물, 장스팬건물, 입체트러스, 현수구조, PEB, 기하비선형 및 재료비선형 해석까지 다양한 분야에 걸쳐 구조엔지니어링 경험과 노하우를 가지고 있습니다.<br />본 웹사이트에서는 회사소개, 사업소개 그리고 공법소개를 확인할 수 있도록 하였고, 주요실적또한 표와 사진으로 제공하여 확인 할 수 있습니다. 고객지원 페이지를 통해 사용자가 궁금한 부분을 홈페이지 관리자에게 문의하여 소통할 수 있게 구축하였습니다.
                    </p>
                </div>
                <div class="gridType">
                    <div class="col4">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>구조계획</h1>
                                <p>Structural Plan</p>
                            </div>
                        </div>
                    </div>
                    <div class="col4">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>구조설계</h1>
                                <p>Structural Design</p>
                            </div>
                        </div>
                    </div>
                    <div class="col4">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>구조도면 작성</h1>
                                <p>Structural Drawing</p>
                            </div>
                        </div>
                    </div>
                    <div class="col4">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>현장지원</h1>
                                <p>Support construction site</p>
                            </div>
                        </div>
                    </div>
                    <div class="between_wrap col4">
                        <div
                            class="between shadow-dark"
                            v-for="i in 3"
                            :key="i"
                        >
                            <p>+</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 detail solution">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Solution</h6>
                    <h1>신뢰와 믿음의<br />시각화</h1>
                    <p>
                        홈페이지 리뉴얼의 가장 큰 핵심은 사용자에게 홈페이지를 통해 이음건설이 '보강공사전문'이라는 이미지를 얼마나 효과적으로 잘 전달할 수 있는가였습니다. 객관적인 지표가 될 수 있는 기업의 경력, 주요실적,
                        사업의 종류, 연혁 등의 사실기반의 정보들을 부각시키고, 실사 위주의 이미지와 블루색상을 사용하여 신뢰감을 표현했습니다.
                        또한, 많은 정보들로 인해 피로하지 않게 선택적 노출을 하였으며, 역동감을 위해 스크롤시 요소들이 움직이도록 구성했습니다.
                    </p>
                </div>
                <div class="roundType">
                    <div class="left_big">
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>기업의 강점 부각</h1>
                            </div>
                        </div>
                    </div>
                    <div class="right_wrap">
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>
                                    결과물들의<br />객관적인 지표
                                </h1>
                            </div>
                        </div>
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>정보들을 탭 형식으로<Br />선택적 노출</h1>
                            </div>
                        </div>
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>
                                    스크롤 시 나타나는<br />
                                    각 요소들에게 움직임 부여
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Main</h6>
                    <h1>메인 화면</h1>
                    <p>
                        메인 최상단은 이음건설의 슬로건이 담긴 와이드한 배너와 함께
                        이음건설의 사업분야소개가 펼쳐져 있습니다. 그 아래에는
                        이음건설의 공법소개 및 실제 시공사진 이음건설과 관련된 다양한 정보들로
                        구성되어있습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_mobile_01.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_mobile_02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_mobile_03.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_mobile_04.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_web_01.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_web_02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_web_03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 about">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>About</h6>
                    <h1>About 이음</h1>
                    <p>
                        About이음에서는 이음의 인사말, 사업영역, CI소개, 연혁등으로 구성되어있습니다.<br />실사 이미지/아이콘으로 각 섹션의 포인트를 직관적으로 표현합니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_web_05.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_mobile_05.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_web_06.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_mobile_06.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Introduce business</h6>
                    <h1>이음건설의<br />다양한 사업소개</h1>
                    <p>
                        이음건설의 다양한 사업소개를 인포그래픽과 그래프, 영상을 제시하여 사용자의 이해도를 높였습니다. 브라우저 스크롤을 함에 따라 나타나는 모션을 통해 정보를 지루하지 않게 전달합니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_web_07.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_web_08.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/eum/img_web_09.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.eum .main_banner {
    background-image: url("~@/assets/images/reference/eum/img_mainBanner.jpg");
}
.reference.eum .roundType .left_big .inner_shadow {
    background-image: url("~@/assets/images/reference/eum/img_gridBg.jpg");
    box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
    color: #fff;
}
.reference.eum .roundType .right_wrap .inner_shadow {
    background: rgb(53, 78, 207);
    background: -moz-linear-gradient(
        top,
        #cdc913 0%,
        rgb(90, 169, 205) 100%
    );
    background: -webkit-linear-gradient(
        top,
        #cdc913 0%,
        rgb(90, 169, 205) 100%
    );
    background: linear-gradient(
        to bottom,
        #cdc913 0%,
        #31a3b4 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a2dad', endColorstr='#682974',GradientType=0 );
    color: #fff;
    box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
}
.reference.eum .about .mockup_basic {
    margin-top: 5rem;
}
</style>