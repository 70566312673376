<template>
    <div class="c_content_container reference sungwon">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/sungwon/logo.svg"
                            alt="성원대학교"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />대학교 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>대학교를 타켓으로한 템플릿 사이트 입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>정보전달</h1>
                    <p>
                        정보 전달과 홍보가 중요한 대학교 사이트는 적절한 아이콘
                        사용과 각 콘테츠의 제목 및 내용이 구분되기 쉽도록
                        작업했습니다.
                    </p>
                </div>
                <div class="img_wrap">
                    <img
                        src="@/assets/images/reference/sungwon/concept.jpg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>메인페이지</h1>
                    <p>
                        메인 페이지에는 <strong>퀵 메뉴</strong>를 배치하여
                        사용자가 좀 더 빠르게 원하는 정보를 찾을 수 있도록
                        했습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/sungwon/main_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile image_shadow">
                        <img
                            src="@/assets/images/reference/sungwon/main_m.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section menu">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MENU</h6>
                    <h1>메뉴</h1>
                    <p>
                        웹에서는 depth2 전체가 한번에 떨어지는 형식의 메뉴로
                        구성하여<br />한 눈에 사이트맵을 볼 수 있습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/sungwon/menu_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mobile image_shadow">
                        <img
                            src="@/assets/images/reference/sungwon/menu_m.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section contents">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONTENTS</h6>
                    <h1>서브페이지</h1>
                    <p>
                        텍스트만으로 이루어진 콘텐츠는 자칫 지루하고 전달력이
                        떨어질 수 있습니다.<br />그런 점을 보완하기 위해 사용자
                        이해에 도움을 줄 이미지와 아이콘을<br />사용하여
                        전달력을 높였습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/sungwon/content01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="image_right">
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/sungwon/content02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/sungwon/responsive01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/sungwon/responsive02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/sungwon/responsive03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section inspiration">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>BUTTON</h6>
                    <h1>위로가기 버튼</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="img_wrap">
                    <img
                        src="@/assets/images/reference/sungwon/button_illust.svg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section detail iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        사용자들의 정보의 이해도를 높이기 위해 직관적인 아이콘을
                        사용했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/sungwon/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/sungwon/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/sungwon/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/sungwon/icon04.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/sungwon/icon05.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/sungwon/icon06.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        신념과 전통은 <strong>네이비 컬러</strong>로, 생동감과
                        에너지는<strong>옐로우 골드 컬러</strong>로
                        표현했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Navy</p>
                        <p>R 11 G 4 B 80</p>
                        <p>#0B0450</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Yellow Gold</p>
                        <p>R 221 G 142 B 18</p>
                        <p>#DD8E12</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        주요 국문과 영문은 <strong>Noto Sans KR</strong>를
                        사용했으며, 비주얼적으로 사용한 영문폰트는
                        <strong>Barlow Semi Condensed</strong>를 사용해 심미성을
                        높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Barlow Semi Condensed<span>|</span
                            ><span>비주얼 영문</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;700&display=swap");
.reference.sungwon .image_shadow {
    border-radius: 0;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.sungwon .mockup_wrap > div:not(:last-child) {
    margin-bottom: 5rem;
}
.reference.sungwon .main_banner {
    background-image: url("~@/assets/images/reference/sungwon/img_mainBanner.jpg");
}
.reference.sungwon .main {
    background: url("~@/assets/images/reference/sungwon/main_bg.png") no-repeat
        top center/contain;
}

.reference.sungwon .inspiration .pt_inner {
    display: flex;
    gap: 5rem;
    align-items: center;
    flex-wrap: wrap;
}
.reference.sungwon .inspiration .pt_inner .intro_wrap {
    width: calc(100% - 5rem - 40rem);
}
.reference.sungwon .inspiration .pt_inner .img_wrap {
    width: 40rem;
}
.reference.sungwon .colorType_01 .box:nth-child(1) {
    background: #0b0450;
}
.reference.sungwon .colorType_01 .box:nth-child(2) {
    background: #dd8e12;
}
.reference.sungwon .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.sungwon .fontType_01 .box_02 {
    font-family: "Barlow Condensed", sans-serif;
}
.reference.sungwon .pages {
    background: linear-gradient(
            to top,
            var(--color-bg-white),
            var(--color-bg-white) 10%,
            transparent 20%
        ),
        url("~@/assets/images/reference/sungwon/pages.jpg") no-repeat center
            top/cover;
}
.reference.sungwon .pages::before {
    background: none;
}
@media screen and (max-width: 768px) {
    .reference.sungwon .inspiration .pt_inner .intro_wrap {
        width: 100%;
    }
    .reference.sungwon .inspiration .pt_inner .img_wrap {
        margin: 0 auto;
    }
}
</style>
