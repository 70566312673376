<template>
  <div class="c_content_container reference vinacts">
    <!--메인배너 START-->
    <div class="main_banner p_center g_dark">
      <div class="inner">
        <div class="title_wrap">
          <div class="logo_wrap">
            <img
              src="@/assets/images/reference/vinacts/logo_vinacts.svg"
              alt=""
            />
          </div>
          <h1 class="title">비넥츠<br />웹 페이지 개발</h1>
          <ol>
            <li class="date">
              <h3>Date</h3>
              <div class="underline"></div>
              <p>22. 12. 09</p>
            </li>
            <li class="position">
              <h3>Position</h3>
              <div class="underline"></div>
              <p>기획, 디자인, 퍼블리싱, 개발</p>
            </li>
            <li class="overview">
              <h3>Overview</h3>
              <div class="underline"></div>
              <p>
                비넥츠는 메타버스 , 실감형 콘텐츠 , 네트워크 게임 등 관련된
                <br />
                실무 경력 20년 이상의 전문가들이 함께 하고 있는 기업입니다.
              </p>
            </li>
          </ol>
          <div class="btn_wrap-web">
            <a href="https://www.vinacts.com/" target="_blank"
              >웹사이트 바로가기</a
            >
          </div>
        </div>
      </div>
    </div>
    <!--메인배너 END-->

    <!--section START-->
    <section class="pt_section challenge">
      <div class="pt_inner">
        <div class="intro_wrap">
          <h6>Challange</h6>
          <h1>현실을 뛰어넘는 기술<Br /> 비넥츠</h1>
          <p>
            비넥츠는 인터렉티브 미디어, 증강현실, 가상현실, 메타버스까지 정확한
            기술과 진보된 방식의 구축 과정으로 한차원 더 높은 수준의 컨텐츠들을
            제작하고 있습니다.<br />
            본 웹 페이지에서는 원페이지로 구성되어 간단한 회사 소개와 함께
            연도별 이력, 그리고 비넥츠의 기술들과 카테고리별 작업물들을 확인할
            수 있도록 구성하였습니다.
          </p>
        </div>
        <div class="gridType">
          <div class="col4">
            <div class="inner">
              <div class="title_wrap">
                <h1>인터렉티브 미디어</h1>
                <p>Interactive Media</p>
              </div>
            </div>
          </div>
          <div class="col4">
            <div class="inner">
              <div class="title_wrap">
                <h1>증강 현실</h1>
                <p>Augmented Reality</p>
              </div>
            </div>
          </div>
          <div class="col4">
            <div class="inner">
              <div class="title_wrap">
                <h1>가상 현실</h1>
                <p>Virtual Reality</p>
              </div>
            </div>
          </div>
          <div class="col4">
            <div class="inner">
              <div class="title_wrap">
                <h1>메타버스</h1>
                <p>Metaverse</p>
              </div>
            </div>
          </div>
          <div class="between_wrap col4">
            <div class="between shadow-dark" v-for="i in 3" :key="i">
              <p>+</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pt_section solution">
      <div class="pt_inner">
        <div class="intro_wrap">
          <h6>Solution</h6>
          <h1>비넥츠의 역동성을<br />사용자의 화면에서도</h1>
          <p>
            컨텐츠 제작 회사라는 테마와, 한 화면으로 구성되어있다는 점을 토대로
            비넥츠 웹페이지를 제작할 때 가장 중요하게 생각한 부분은 바로
            역동성이었습니다. 이를 바탕으로 가장 핵심이 되는 메인 상단 배너는
            비넥츠의 결과물들을 영상으로 담아 제시하였고, 연도별 성과와 같은
            정보들을 단순히 나열하기보다는 카테고리 탭 형식으로 사용자가 궁금한
            정보를 빠르게 노출 시킬 수 있도록 작업하였습니다. 또한 페이지를
            스크롤했을 때 나타나는 각 요소들에 다양한 동작을 추가하여 페이지를
            보는 사람들에게 시각적인 효과를 주고, 정보들을 강조할 수 있도록
            구현하였습니다.
          </p>
        </div>
        <div class="roundType">
          <div class="left_big">
            <div class="inner_shadow">
              <div class="text_wrap">
                <h1>역동적인 웹 페이지</h1>
              </div>
            </div>
          </div>
          <div class="right_wrap">
            <div class="inner_shadow">
              <div class="text_wrap">
                <h1>
                  메인 배너에 비넥츠의<Br />
                  결과물들을 영상으로 노출
                </h1>
              </div>
            </div>
            <div class="inner_shadow">
              <div class="text_wrap">
                <h1>정보들을 탭 형식으로<Br />선택적 노출</h1>
              </div>
            </div>
            <div class="inner_shadow">
              <div class="text_wrap">
                <h1>
                  스크롤 시 나타나는<br />
                  각 요소들에게 움직임 부여
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pt_section detail">
      <div class="pt_inner">
        <div class="intro_wrap">
          <h6>Main</h6>
          <h1>메인 화면</h1>
          <p>
            메인 최상단은 비넥츠의 슬로건이 담긴 영상 배너와 함께 비넥츠의
            클라이언트들이 정리되어 있습니다. 그 아래에는 비넥츠의 주요 서비스와
            연도별 성과들을 정리하여 제시하였습니다.
          </p>
        </div>
        <div class="mockup_basic">
          <div class="mobile image_shadow">
            <div class="image_inner">
              <img
                src="@/assets/images/reference/vinacts/img_mobile_01.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="web image_shadow">
            <div class="image_inner">
              <img
                src="@/assets/images/reference/vinacts/img_web_01.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pt_section detail">
      <div class="pt_inner">
        <div class="intro_wrap">
          <h6>Technology</h6>
          <h1>기술 소개</h1>
          <p>
            그 다음으로는 비넥츠의 기술들을 나열하였습니다. 크게 3개의
            소제목으로 구분하였고, 각 요소에 스크롤이 도달했을 때, 해당 이미지
            및 아이콘들이 각각의 모션으로 나타나도록 구현하였습니다.
          </p>
        </div>
        <div class="mockup_basic">
          <div class="mobile image_shadow">
            <div class="image_inner">
              <img
                src="@/assets/images/reference/vinacts/img_mobile_02.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="web image_shadow">
            <div class="image_inner">
              <img
                src="@/assets/images/reference/vinacts/img_web_02.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pt_section detail">
      <div class="pt_inner">
        <div class="intro_wrap">
          <h6>Work & Contact</h6>
          <h1>작업 및 Contact</h1>
          <p>
            그 아래로는 비넥츠의 작업들을 카테고리별 탭과 함께 배치하였습니다.
            그리고 9개 이상의 콘텐츠가 노출될 때는 more 버튼을 추가하여 전체적인
            스크롤 높이가 길어지는 것을 방지하였습니다.<br />
            각 콘텐츠를 클릭하였을 때는 관련 영상으로 링크를 이동하거나, 이미지
            슬라이드가 되는 팝업을 띄우는 방식으로 작업하였습니다.<br />
            마지막은 비넥츠의 CEO 및 주요 구성원들과 함께 연락할 수 있는 Contact
            내용들을 정리했습니다.
          </p>
        </div>
        <div class="mockup_basic">
          <div class="mobile">
            <div class="image_shadow">
              <div class="image_inner">
                <img
                  src="@/assets/images/reference/vinacts/img_mobile_03_01.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="image_shadow">
              <div class="image_inner">
                <img
                  src="@/assets/images/reference/vinacts/img_mobile_03_02.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="web">
            <div class="image_shadow">
              <div class="image_inner">
                <img
                  src="@/assets/images/reference/vinacts/img_web_03_01.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="image_shadow">
              <div class="image_inner">
                <img
                  src="@/assets/images/reference/vinacts/img_web_03_02.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--section END-->
  </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>
<style scoped>
.reference.vinacts .main_banner {
  background-image: url("~@/assets/images/reference/vinacts/img_mainBanner.jpg");
}
.reference.vinacts .main_banner .inner .title_wrap .logo_wrap {
  height: 6rem;
}
.reference.vinacts .gridType .inner {
  background: linear-gradient(
    to bottom,
    rgba(154, 45, 173, 1) 0%,
    rgba(104, 41, 116, 1) 100%
  );
  color: #fff;
}
.reference.vinacts .gridType .between_wrap .between {
  color: #9a2dad;
  font-size: var(--font-size-xx-large);
}

.reference.vinacts .roundType .left_big .inner_shadow {
  background-image: url("~@/assets/images/reference/vinacts/img_gridBg.jpg");
  box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
}
.reference.vinacts .roundType .right_wrap .inner_shadow {
  background: rgb(154, 45, 173);
  background: -moz-linear-gradient(
    top,
    rgba(154, 45, 173, 1) 0%,
    rgba(104, 41, 116, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(154, 45, 173, 1) 0%,
    rgba(104, 41, 116, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(154, 45, 173, 1) 0%,
    rgba(104, 41, 116, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a2dad', endColorstr='#682974',GradientType=0 );
  color: #fff;
  box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
}
.reference.vinacts .detail {
  background-color: #f5f5f5;
}
</style>
