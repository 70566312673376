<template>
    <div class="c_content_container reference papier">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/papier/logo.svg"
                            alt="페퍼"
                        />
                    </div>
                    <h1 class="title">페퍼<br />웹사이트 리뉴얼</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 04. 01</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>페퍼는 명함, 스티커, 행택 등 다양한 제품의<br />인쇄서비스를 제공하는 업체입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="https://www.papier.co.kr/" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section subType_02 challenge">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Challange</h6>
                    <h1>믿고 맏길 수 있는<br />품질을 자랑하는 페퍼</h1>
                    <p>
                        페퍼는 인쇄전문 회사로 고객이 쉽고빠르게 원하는 인쇄물을 합리적인 비용으로 만들 수 있도록 서비스를 제공합니다. 또한 인쇄물을 만드는데 필요한 다양한 부가 서비스도 제공합니다.
                    </p>
                </div>
                <div class="gridType gap_mini">
                    <div class="col6">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>디자인 선택</h1>
                                <p>Choice of a design</p>
                            </div>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>커스터마이징</h1>
                                <p>Customizing Design</p>
                            </div>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>결제</h1>
                                <p>Payment</p>
                            </div>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>인쇄</h1>
                                <p>Print</p>
                            </div>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>배송</h1>
                                <p>Delievery</p>
                            </div>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>검수</h1>
                                <p>Inspection</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 detail solution">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Solution</h6>
                    <h1>편리하고 쉬운<br />서비스의 이용</h1>
                    <p>
                        홈페이지 리뉴얼의 가장 큰 핵심은 사용자에게 홈페이지를 통해 페퍼의 다양한 서비스를 더 편하고 쉽게 이용할 수 있도록 하는것이였습니다. 실사 위주의 이미지와 블루색상을 사용하여 신뢰감과 밝은 분위기를 표현했습니다. 또한, 많은 정보들로 인해 피로하지 않도록 더보기 버튼을 두어 필요한 정보만 노출될 수 있도록 하였습니다.
                    </p>
                </div>
                <div class="roundType">
                    <div class="left_big">
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>서비스이용의 시각화</h1>
                            </div>
                        </div>
                    </div>
                    <div class="right_wrap">
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>
                                    제공되는<br />서비스의 분류  
                                </h1>
                            </div>
                        </div>
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>정보들을 탭 형식으로<Br />선택적 노출</h1>
                            </div>
                        </div>
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>
                                    직관적인 아이콘과<br />
                                    실사이미지
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Main</h6>
                    <h1>메인 화면</h1>
                    <p>
                        메인 최상단은 진행중인 프로모션의 와이드한 배너로 사용자들이 쉽게 접근할수 있도록 유도합니다. 또한 아래에는 서비스의 종류와 이용방법들로 간편하게 구성하여 사용자들이 원하는 정보를 많은 클릭없이 얻을 수 있도록 하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/papier/img_mobile_01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/papier/img_web_01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 editor">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Editor</h6>
                    <h1>원하는 디자인으로<br />쉽고간편하게 주문제작</h1>
                    <p>
                        심플한 레이아웃과 직관적인 아이콘으로 구성된 에디터는 사용자가 원하는 디자인을 제작할 수 있도록 도와줍니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/papier/img_mobile_02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/papier/img_web_02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
               
            </div>
        </section>

        <section class="pt_section subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>My page</h6>
                    <h1>마이페이지<br /></h1>
                    <p>
                        주문내역, 취소내역, 리뷰관리, 견적문의 회원들의 정보를 관리하는 페이지입니다. 
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/papier/img_mobile_03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/papier/img_web_03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.papier .main_banner {
    background-image: url("~@/assets/images/reference/papier/img_mainBanner.jpg");
}
.reference.papier .roundType .left_big .inner_shadow {
    background-image: url("~@/assets/images/reference/papier/img_gridBg.jpg");
    box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
    color: #fff;
}
.reference.papier .roundType .right_wrap .inner_shadow {
    background: rgb(53, 78, 207);
    background: -moz-linear-gradient(
        top,
        #bbaa90 0%,
        #776658 100%
    );
    background: -webkit-linear-gradient(
        top,
        #bbaa90 0%,
        #776658 100%
    );
    background: linear-gradient(
        to bottom,
        #bbaa90 0%,
        #776658 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a2dad', endColorstr='#682974',GradientType=0 );
    color: #fff;
    box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
}
.reference.mpc .about .mockup_basic {
    margin-top: 5rem;
}


</style>