<template>
    <div class="c_content_container reference newflex">
        <!--메인배너 START-->
        <div class="main_banner p_center g_light text_dark">
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/newflex/logo_newflex.svg"
                            alt=""
                        />
                    </div>
                    <h1 class="title">뉴플렉스몰<br />쇼핑몰 사이트 개발</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱, 개발</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>
                                뉴플렉스몰은 해외 명품부터, 뷰티, 가전제품 등을
                                판매하는 <br />회원 전용 쇼핑몰 사이트입니다.
                            </p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="https://www.nfxmall.com/" target="_blank"
                            >웹사이트 바로가기</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section challenge">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Challange</h6>
                    <h1>대한민국 NO.1 명품 쇼핑몰<Br />뉴플렉스몰</h1>
                    <p>
                        뉴플렉스몰은 해외 명품부터, 뷰티, 가전제품 등을 판매하는
                        회원 전용 쇼핑몰로 특화된 상품을 특화된 가격과 정책으로
                        서비스를 제공하고 있는 쇼핑몰입니다. <br />
                        본 쇼핑몰에서는 다양한 카테고리의 상품들과 함께 특별한
                        기획전, 이벤트, 그리고 상품 관련 매거진을 선보이고
                        있습니다. 또한 자주 묻는 질문들은 카테고리화하여
                        사용자가 쉽게 확인할 수 있도록 제공하였고,
                        마이페이지에서는 사용자의 주문 내역, 작성한 리뷰, 작성한
                        상품 문의 등을 조회할 수 있도록 구축하였습니다.
                    </p>
                </div>
                <div class="gridType gap_mini">
                    <div class="col6">
                        <div class="inner shadow_inset-light">
                            <div class="icon_wrap">
                                <img
                                    src="@/assets/images/reference/newflex/icon_ch_01.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h6>상품</h6>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner shadow_inset-light">
                            <div class="icon_wrap">
                                <img
                                    src="@/assets/images/reference/newflex/icon_ch_02.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h6>기획전</h6>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner shadow_inset-light">
                            <div class="icon_wrap">
                                <img
                                    src="@/assets/images/reference/newflex/icon_ch_03.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h6>이벤트</h6>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner shadow_inset-light">
                            <div class="icon_wrap">
                                <img
                                    src="@/assets/images/reference/newflex/icon_ch_04.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h6>매거진</h6>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner shadow_inset-light">
                            <div class="icon_wrap">
                                <img
                                    src="@/assets/images/reference/newflex/icon_ch_05.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h6>FAQ</h6>
                        </div>
                    </div>
                    <div class="col6">
                        <div class="inner shadow_inset-light">
                            <div class="icon_wrap">
                                <img
                                    src="@/assets/images/reference/newflex/icon_ch_06.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="text_wrap">
                            <h6>마이페이지</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section solution">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Solution</h6>
                    <h1>대한민국 NO.1 명품 쇼핑몰<br />뉴플렉스몰</h1>
                    <p>
                        쇼핑몰 사이트의 특성상 많은 정보들과 이미지들이 화면에
                        노출될 수 밖에 없습니다. 따라서 많은 정보들을 깔끔하고
                        정돈되게 보여주는 것이 뉴플렉스몰 디자인의
                        핵심이었습니다. 전체적으로 넓은 여백의 화이트 톤의
                        디자인은 텍스트와 이미지들을 더욱 부각시키며, 옅은
                        그레이 톤의 라인 및 배경 색상은 섹션의 구분을 지어주고,
                        입력창과 같은 특수한 형태의 UI들을 나타내는데
                        사용되었습니다. 또한 포인트 색감인 코랄 컬러는 더욱
                        돋보여야 할 정보들을 강조함과 동시에 자칫 밋밋해질 수
                        있는 페이지에 시각적 효과를 부여합니다.
                    </p>
                </div>
                <div class="pointType">
                    <div class="centered">
                        <p>
                            많은 정보들을<br />
                            <strong>시각적으로 정돈되도록</strong>
                        </p>
                    </div>
                    <div class="flexed_wrap">
                        <div class="left">
                            <div class="point">
                                <div class="line"></div>
                                <div class="circle"></div>
                            </div>
                            <div class="inner_shadow">
                                <div class="border_line">
                                    <div class="inner">
                                        넓은 여백과<br />
                                        밝은 톤의 구분
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="inner_shadow">
                                <div class="inner">포인트 컬러</div>
                            </div>
                            <div class="point">
                                <div class="circle"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro_wrap">
                    <h6>Solution</h6>
                    <h1>모든 디바이스에서도<br />완벽한 화면을</h1>
                    <p>
                        또한 본 쇼핑몰 사이트는 모든 디바이스에서 사용자에게
                        가장 특화된 형태를 보여주기 위해 웹 앱 사이트로
                        작업되었습니다. 화면의 요소들이 모두 반응형으로 작동하여
                        모든 디바이스 화면 사이즈에서 최적의 레이아웃으로
                        나타나며, 웹에서는 헤더에 위치한 메뉴들이 모바일
                        화면에서는 하단의 탭 바 형식으로 구성되는 등의 디테일한
                        부분들이 적용되었습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_solution_app.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_solution_tablet.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_solution_web.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Home</h6>
                    <h1>홈</h1>
                    <p>
                        뉴플렉스 쇼핑몰의 홈 화면입니다.<br />
                        홈 화면은 타임딜, 신제품, 기획전 등 가장 많은 정보를
                        담고 있는 화면입니다. 따라서 본문보다 큰 서체의 소제목을
                        추가하고, 여백과 옅은 그레이 라인을 활용하여 각기 다른
                        섹션 들을 구분하였습니다. 또한 각 섹션에 맞는 여러가지
                        레이아웃들을 적용하여, 명확한 정보 전달과 함께 시각적인
                        요소를 제공하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_mobile_01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_web_01.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Product</h6>
                    <h1>상품</h1>
                    <p>
                        뉴플렉스 쇼핑몰의 판매 상품들을 확인할 수 있는
                        화면입니다.<br />
                        무한 스크롤 방식을 사용하여 판매 중인 모든 상품들을
                        확인할 수 있도록 작업하였으며, 왼쪽상단의 필터 버튼들을
                        클릭하면 접혀있던 내용들을 확인하고 사용자가 원하는
                        정보의 제품만 찾아볼 수 있도록 구성하였습니다.<br />
                        재입고 버튼 및 할인가와 같은 강조되는 요소들은 코랄색
                        컬러로 포인트를 주었습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_mobile_02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_web_02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Exhibition</h6>
                    <h1>기획전</h1>
                    <p>
                        뉴플렉스 쇼핑몰의 기획전들을 확인할 수 있는
                        화면입니다.<br />
                        3단 그리드의 정렬로 기획전의 제목, 내용, 기간 등을
                        제시하고, 그 중 진행 중인 기획전이 무엇인지를 사용자가
                        쉽게 파악할 수 있도록 왼쪽 상단의 컬러로 구분된 태그를
                        포인트로 주었습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_mobile_03.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/newflex/img_web_03_01.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/newflex/img_web_03_02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>FAQ</h6>
                    <h1>FAQ</h1>
                    <p>
                        뉴플렉스 쇼핑몰의 FAQ 화면입니다.<br />
                        사용자들이 원하는 내용을 찾기 쉽도록 검색바를 두고
                        카테고리로 내용들을 구분하였으며, 질문을 클릭했을 때
                        관련 답변이 펼쳐지는 아코디언 형식으로 구성하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_mobile_04.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_web_04.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>My Page</h6>
                    <h1>마이 페이지</h1>
                    <p>
                        뉴플렉스 쇼핑몰의 마이 페이지 화면입니다.<br />
                        마이페이지 상단에는 로그인 정보, 쿠폰, 포인트 등의 주요
                        정보들을 쉽게 알아볼 수 있도록 배치하였습니다. 그리고
                        하단에는 주문내역, 리뷰, 상품 문의, 정보 수정 등
                        세부적인 내용들을 좀 더 쉽게 확인할 수 있도록 탭
                        형식으로 구성하여 정보들을 나열하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_mobile_05.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/newflex/img_web_05.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/newflex/img_web_05_02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Order</h6>
                    <h1>주문서 작성</h1>
                    <p>
                        뉴플렉스 쇼핑몰의 주문서 작성 화면입니다.<br />
                        주문서 좌측 영역에는 주문하기 위한 정보들을 작성할 수
                        있는 폼들을 정리하였으며, 우측은 총 결제금액과 결제
                        버튼이 고정값으로 항상 보이도록 작업하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/newflex/img_mobile_06_01.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="image_shadow">
                            <div class="image_inner">
                                <img
                                    src="@/assets/images/reference/newflex/img_mobile_06_02.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="web image_shadow">
                        <div class="image_inner">
                            <img
                                src="@/assets/images/reference/newflex/img_web_06.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--section END-->

        <!--footer START-->
        <!-- <div class="pt_footer">
            <footer>
                <img
                    src="@/assets/images/reference/newflex/logo_newflex.svg"
                    alt=""
                />
            </footer>
        </div> -->
        <!--footer END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>
<style scoped>
.reference.newflex .main_banner {
    background-image: url("~@/assets/images/reference/newflex/img_mainBanner.png");
}

.reference.newflex .solution div + .intro_wrap {
    margin-top: 10rem;
}
.reference.newflex .solution {
    background-color: #f5f5f5;
}
.reference.newflex .solution .mockup_basic {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 6rem;
}
.reference.newflex .solution .mockup_basic > div:nth-of-type(1) {
    width: calc((100% - 4rem) / 3);
}
.reference.newflex .solution .mockup_basic > div:nth-of-type(2) {
    width: calc((100% - 4rem) / 1.5);
}
.reference.newflex .solution .mockup_basic > div:nth-of-type(3) {
    width: 100%;
}
.reference.newflex .pointType .centered strong {
    font-weight: var(--font-w-bold);
}
.reference.newflex .pointType .flexed_wrap .left .border_line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 70%;
    min-width: 11rem;
    min-height: 11rem;
    border-radius: var(--border-radius-full);
    border: 2px solid #ddd;
}
.reference.newflex .pointType .flexed_wrap .left .border_line .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: 85%;
    border-radius: var(--border-radius-full);
    background-color: #f5f5f5;
    box-shadow: inset 2px 3px 10px #ddd;
}
.reference.newflex .pointType .flexed_wrap .inner_shadow > .inner {
    min-width: 11rem;
    min-height: 11rem;
    background-color: var(--color-newflex-primary);
    color: #fff;
    box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 768px) {
    .reference.newflex .solution .mockup_basic > div:nth-of-type(1) {
        width: 100%;
    }
    .reference.newflex .solution .mockup_basic > div:nth-of-type(2) {
        width: 100%;
    }
}
</style>
