<template>
    <div class="c_content_container reference freshmart">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/freshmart/logo.svg"
                            alt="프레쉬마트"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />식료품 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>식료품마트를 타켓으로한 템플릿 사이트 입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section detail concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>신선하고 깨끗한</h1>
                    <p>
                        식품 사이트의 신선하고 깨끗한 이미지를 주기 위해 심플한
                        레이아웃으로 구성했으며, 상품의 정보가 잘 전달될 수
                        있도록 필요한 곳에만 메인 컬러와 서브 컬러를
                        사용하였습니다. 또한, 라인 컬러는 너무 어둡지 않은
                        컬러를 사용해서 복잡함과 답답함 없이 개방감이 들게
                        하였습니다.
                    </p>
                </div>
                <div class="img_wrap">
                    <img
                        src="@/assets/images/reference/freshmart/concept.svg"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>메인페이지</h1>
                    <p>
                        메인 페이지의 메인 배너는 현재 진행 중인 프로모션 상품을
                        보여줌으로써 상황에 따라
                        <strong>유연하게 변화</strong> 할 수 있습니다. 또한 타임
                        딜, 베스트 아이템, 특가 상품, 엠디 추천의 다양한 상품
                        카테고리로 고객들은 원하는 상품에 더 쉽게 접근할 수
                        있습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="mockup_basic">
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/freshmart/main_web01.jpg"
                                alt=""
                            />
                        </div>
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/freshmart/main_mo01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="mockup_basic">
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/freshmart/main_mo02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="web image_shadow">
                            <img
                                src="@/assets/images/reference/freshmart/main_web02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section contents">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MENU</h6>
                    <h1>메뉴</h1>
                    <p>
                        상품의 종류를 좀 더 <strong>세분화</strong>하고 비슷한
                        품목끼리 묶어 찾기 쉽게 했습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile image_shadow">
                        <img
                            src="@/assets/images/reference/freshmart/menu_mo.jpg"
                            alt=""
                        />
                    </div>
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/freshmart/menu_web.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section dinfo">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DETAIL INFORMATION</h6>
                    <h1>정보의 구분화</h1>
                    <p>
                        상품 상세 소개 페이지는 많은 양의 텍스트를 정보별로
                        구분을 짓는 게 중요했으며 특별히 강조할 부분은 폰트의
                        색상을 다르게 하여 주목성을 높였습니다.
                        <strong>직관적인 아이콘</strong>의 사용으로 사용자의
                        <strong>정보 습득력</strong>을 높일 수 있습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/freshmart/detailinfo01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="web">
                        <img
                            src="@/assets/images/reference/freshmart/detailinfo02.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section mypg">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MY PAGE</h6>
                    <h1>마이페이지</h1>
                    <p>
                        마이페이지 메뉴는 <strong>직관적인 아이콘</strong>을
                        사용하여 심미성과 주목성을 높였습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="web image_shadow">
                        <img
                            src="@/assets/images/reference/freshmart/mypage_web.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic image_right">
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/freshmart/mypage_mo01.jpg"
                                alt=""
                            />
                        </div>
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/freshmart/mypage_mo02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>다양한 디바이스에 최적화</h1>
                    <p>
                        웹뿐만아니라 다양한 디바이스에서도<br />깨지지 않는
                        레이아웃을 보실수 있도록 사이트를 구축했습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_shadow">
                        <img
                            src="@/assets/images/reference/freshmart/responsive01.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/freshmart/responsive02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/freshmart/responsive03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail iconType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>아이콘 스타일</h1>
                    <p>
                        사용자들의 정보의 이해도를 높이기 위해 직관적인 라인
                        아이콘을 사용했습니다.
                    </p>
                </div>
                <div class="icon_wrap">
                    <div>
                        <img
                            src="@/assets/images/reference/freshmart/icon01.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/freshmart/icon02.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/freshmart/icon03.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/freshmart/icon04.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/freshmart/icon05.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            src="@/assets/images/reference/freshmart/icon06.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        신선함과 깨끗함은 <strong>블루컬러</strong>로, 생동감과
                        에너지는<strong>옐로우 오렌지 컬러</strong>로
                        표현했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Blue</p>
                        <p>R 0 G 93 B 185</p>
                        <p>#005DB9</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Yellow Orange</p>
                        <p>R 255 G 136 B 0</p>
                        <p>#FF8800</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>Noto Sans KR</strong>, 영문과 숫자는
                        <strong>Urbanist</strong>를 사용해 가독성과 심미성을
                        높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Urbanist<span>|</span><span>영문,숫자</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                        <p class="preview">0123456789</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages gd_white backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700&display=swap");

.reference.freshmart .image_shadow {
    border-radius: 0;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.freshmart .mockup_wrap .mockup_basic {
    margin-top: 5rem;
}
.reference.freshmart .main_banner {
    background-image: url("~@/assets/images/reference/freshmart/img_mainBanner.jpg");
}
.reference.freshmart .concept .img_wrap {
    margin: 0 auto;
    max-width: 90rem;
}
.reference.freshmart .main {
    background: #f4f4f4;
}
.reference.freshmart .main .mockup_wrap .mockup_basic:not(:last-child) {
    margin-bottom: 5rem;
}
.reference.freshmart .colorType_01 .box:nth-child(1) {
    background: #005db9;
}
.reference.freshmart .colorType_01 .box:nth-child(2) {
    background: #ff8800;
}
.reference.freshmart .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.freshmart .fontType_01 .box_02 {
    font-family: "Urbanist", sans-serif;
}
.reference.freshmart .pages {
    background: url("~@/assets/images/reference/freshmart/pages.jpg") no-repeat
        center bottom/cover;
}
</style>
