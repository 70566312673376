<template>
    <div class="c_content_container reference mpc">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/mpc/logo.svg"
                            alt="무궁화캐피탈"
                        />
                    </div>
                    <h1 class="title">무궁화캐피탈<br />웹사이트 구축</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>무궁화캐피탈은 무궁화 금융그룹의 관계회사로서<br />부동산 금융의 전문성을 갖춘 여신전문 금융회사입니다.</p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="https://www.mghcap.co.kr/" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section subType_02 challenge">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Challange</h6>
                    <h1>부동산 금융시장을 선도하는<br />우량 여신전문 회사</h1>
                    <p>
                        관계사와의 시너지 창출을 통하여 부동산 금융 IB 및 자금중개, 주선업무를 성장동력으로 부동산 금융시장을 선도하는 우량 여신전문 회사로 도약하고자 하며, 투자금융, 신기술사업금융 시장에 진출하여 여신전문 종합금융회사로 성장하고자 합니다.
                    </p>
                </div>
                <div class="gridType">
                    <div class="col5">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>고객존중</h1>
                                <p>Respect clients</p>
                            </div>
                        </div>
                    </div>
                    <div class="col5">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>신뢰정직</h1>
                                <p>Trust and Honest</p>
                            </div>
                        </div>
                    </div>
                    <div class="col5">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>가치창출</h1>
                                <p>Value Creation</p>
                            </div>
                        </div>
                    </div>
                    <div class="col5">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>혁신주도</h1>
                                <p>Innovative Leadership</p>
                            </div>
                        </div>
                    </div>
                    <div class="col5">
                        <div class="inner">
                            <div class="title_wrap">
                                <h1>동반성장</h1>
                                <p>Growing together</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 detail solution">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Solution</h6>
                    <h1>신뢰와 믿음의<br />시각화</h1>
                    <p>
                        홈페이지 리뉴얼의 가장 큰 핵심은 사용자에게 홈페이지를 통해 무궁화캐피탈이 '여신전문 종합금융회사'라는 이미지를 얼마나 효과적으로 잘 전달할 수 있는가였습니다. 객관적인 지표가 될 수 있는 기업의 경력, 주요실적, 사업의 종류, 연혁 등의 사실기반의 정보들을 부각시키고, 아이콘과 인포그래픽을 사용하여 정보의 이해도를 높였습니다.
                    </p>
                </div>
                
                <div class="roundType">
                    <div class="left_big">
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>기업의 강점 부각</h1>
                            </div>
                        </div>
                    </div>
                    <div class="right_wrap">
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>
                                    결과물들의<br />객관적인 지표
                                </h1>
                            </div>
                        </div>
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>인포그래픽을 사용한<Br />정보의 시각화</h1>
                            </div>
                        </div>
                        <div class="inner_shadow">
                            <div class="text_wrap">
                                <h1>
                                    스크롤 시 나타나는<br />
                                    각 요소들에게 움직임 부여
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Main</h6>
                    <h1>메인 화면</h1>
                    <p>
                        메인 최상단 배너에는 줌인 효과를 주어 임팩트 있는 메인으로 표현했으며<br />상품 안내, 온라인 상담, 공지사항 등 고객들이 필요한 정보를 한눈에 찾기 쉽도록 배치하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/mpc/img_mobile_01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/mpc/img_web_01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02 about">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>About</h6>
                    <h1>회사소개</h1>
                    <p>
                        회사소개는 회사개요, CEO인사말, 경영공시, 오시는길, 채용정보 페이지로 구성되어있으며, 각 페이지마다 컨텐츠에 맞는 아이콘, 일러스트 그리고 인포그래픽 사용하여 사용자가 지루하지않고 정보를 잘 이해할 수 있도록 하였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/mpc/img_mobile_02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/mpc/img_web_02.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Introduce business</h6>
                    <h1>무궁화캐피탈의<br />다양한 사업소개</h1>
                    <p>
                        무궁화캐피탈의 다양한 사업소개를 내용과 맞는<br />적절한 인포그래픽과 아이콘을 사용하여 사용자의 이해도를 높였습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/mpc/img_mobile_03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/mpc/img_web_03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section subType_02">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>Customer Services</h6>
                    <h1>온라인 상담</h1>
                    <p>
                        사용자들이 상담 양식에 내용을 입력하면<br />빠르게 데이터가 전송되어 답변을 받을 수 있도록 했습니다.
                    </p>
                </div>
                <div class="mockup_basic">
                    <div class="mobile">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/mpc/img_mobile_04.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="web">
                        <div class="image_inner image_shadow">
                            <img
                                src="@/assets/images/reference/mpc/img_web_04.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.mpc .main_banner {
    background-image: url("~@/assets/images/reference/mpc/img_mainBanner.jpg");
}
.reference.mpc .roundType .left_big .inner_shadow {
    background: url("~@/assets/images/reference/mpc/img_gridBg.jpg")no-repeat center/cover;
    box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
    color: #fff;
}
.reference.mpc .roundType .right_wrap .inner_shadow {
    background: rgb(53, 78, 207);
    background: -moz-linear-gradient(
        top,
        #002d7a 0%,
        rgb(65, 156, 236) 100%
    );
    background: -webkit-linear-gradient(
        top,
        #002d7a 0%,
        rgb(65, 156, 236) 100%
    );
    background: linear-gradient(
        to bottom,
        #6579b8 0%,
        #7c94de 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a2dad', endColorstr='#682974',GradientType=0 );
    color: #fff;
    box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.3);
}
.reference.mpc .about .mockup_basic {
    margin-top: 5rem;
}


</style>