<template>
    <div class="c_content_container reference hanvill">
        <!--메인배너 START-->
        <div class="main_banner g_dark p_center">
            <!-- <div class="gradient"></div> -->
            <div class="inner">
                <div class="title_wrap">
                    <div class="logo_wrap">
                        <img
                            src="@/assets/images/reference/hanvill/logo.svg"
                            alt="한빌"
                        />
                    </div>
                    <h1 class="title">템플릿:<br />아파트 분양 사이트</h1>
                    <ol>
                        <li class="date">
                            <h3>Date</h3>
                            <div class="underline"></div>
                            <p>22. 12. 09</p>
                        </li>
                        <li class="position">
                            <h3>Position</h3>
                            <div class="underline"></div>
                            <p>기획, 디자인, 퍼블리싱</p>
                        </li>
                        <li class="overview">
                            <h3>Overview</h3>
                            <div class="underline"></div>
                            <p>
                                아파트 분양을 타켓으로한 템플릿 사이트 입니다.
                            </p>
                        </li>
                    </ol>
                    <div class="btn_wrap-web">
                        <a href="" target="_blank">웹사이트 바로가기</a>
                    </div>
                </div>
            </div>
        </div>
        <!--메인배너 END-->

        <!--section START-->
        <section class="pt_section detail concept">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CONCEPT</h6>
                    <h1>절제된 고급스러움</h1>
                    <p>
                        곡선의 사용보다는 <strong>직선의 사용</strong>을 높여
                        건축의 선을 표현했으며, 세련되면서 깔끔한 느낌이 들도록
                        레이아웃을 구성했습니다.또한, 이미지 사이즈를 크게 하여
                        임팩트있으면서도 시원해 보이도록 했습니다.
                    </p>
                </div>
            </div>
        </section>

        <section class="pt_section main">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>MAIN</h6>
                    <h1>적절한 이미지사용</h1>
                    <p>
                        사이트의 얼굴인 메인페이지에 브랜드가 보여주고자 하는
                        아이덴티티를 효과적으로 전달할 수 있도록
                        <strong>적절한 이미지</strong>사용과
                        <strong>컨텐츠를 한눈에</strong> 보기 쉽게
                        배치하였습니다.<br />
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_centered image_shadow">
                        <img
                            src="@/assets/images/reference/hanvill/main02.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/hanvill/m_main01.jpg"
                                alt=""
                            />
                        </div>
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/hanvill/m_main02.jpg"
                                alt=""
                            />
                        </div>
                        <div class="mobile image_shadow">
                            <img
                                src="@/assets/images/reference/hanvill/m_main03.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section brand">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>BRAND</h6>
                    <h1>개성있는 레이아웃</h1>
                    <p>
                        브랜드의 미션과비전, 핵심가치, BI 각 컨텐츠의 맞게
                        개성있는 레이아웃으로 디자인하였으며, 필요한 정보를 쉽게
                        찾아볼수있습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_left">
                        <div class="web">
                            <img
                                src="@/assets/images/reference/hanvill/brand01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="mockup_basic">
                        <div class="web">
                            <img
                                src="@/assets/images/reference/hanvill/brand03.jpg"
                                alt=""
                            />
                        </div>
                        <div class="web">
                            <img
                                src="@/assets/images/reference/hanvill/brand04.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="image_centered">
                        <div class="web">
                            <img
                                src="@/assets/images/reference/hanvill/brand01.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section detail cs_sv">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>CUSTOMER SERVICES</h6>
                    <h1>편리한 고객센터</h1>
                    <p>
                        고객센터 페이지를 통해 업데이트되는 공지사항 확인과
                        궁금한 사항들에 대해 질문들을 하실수 있습니다. 1:1 고객
                        문의 같은 경우는 모든 페이지에 고정되어있는
                        <strong>상담 아이콘 버튼</strong>을 누르시면 더 쉽게
                        상담이 가능합니다.
                    </p>
                </div>
                <div class="image_centered icon">
                    <img
                        src="@/assets/images/reference/hanvill/icon_cs.svg"
                        alt=""
                    />
                </div>
                <div class="image_centered">
                    <img
                        src="@/assets/images/reference/hanvill/cs.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

        <section class="pt_section responsive">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>RESPONSIVE WEB</h6>
                    <h1>최적화된 반응형</h1>
                    <p>
                        다양한 멀티 디바이스에 따라 최적의 사이즈에 대응할 수
                        있도록 디자인을 진행하였습니다.
                    </p>
                </div>
                <div class="mockup_wrap">
                    <div class="image_centered image_shadow">
                        <img
                            src="@/assets/images/reference/hanvill/responsive_w.jpg"
                            alt=""
                        />
                    </div>
                    <div class="mockup_basic">
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/hanvill/responsive_t.jpg"
                                alt=""
                            />
                        </div>
                        <div class="image_shadow">
                            <img
                                src="@/assets/images/reference/hanvill/responsive_m.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section colorType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>브랜드컬러</h1>
                    <p>
                        건축물의 배경이 되어주는
                        <strong>하늘과 땅</strong> 자연의 색상을 가져와 하늘은
                        <strong>블루</strong>로 정했으며 서브컬러는 풀, 산
                        자연의 생명을 상징하는 컬러인 <strong>그린</strong>을
                        사용했습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box">
                        <p class="tit">MAIN COLOR</p>
                        <p>Mustard</p>
                        <p>R 90 G 118 B 174</p>
                        <p>#5A76AE</p>
                    </div>
                    <div class="box">
                        <p class="tit">SUB COLOR</p>
                        <p>Black</p>
                        <p>R 148 G 162 B 45</p>
                        <p>#94A22D</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section fontType_01">
            <div class="pt_inner">
                <div class="intro_wrap">
                    <h6>DESIGN GUIDE</h6>
                    <h1>폰트</h1>
                    <p>
                        국문은 <strong>Noto Sans KR</strong>, 영문과 숫자는
                        <strong>Urbanist</strong>를 사용해 가독성과 심미성을
                        높였습니다.
                    </p>
                </div>
                <div class="flex_wrap">
                    <div class="box_01">
                        <p class="tit">
                            Noto Sans KR<span>|</span><span>국문,숫자</span>
                        </p>
                        <p class="present">가</p>
                        <p class="weight">
                            <span class="re">Regular</span>
                            <span class="me">Medium</span>
                            <span class="bo">Bold</span>
                        </p>
                        <p class="preview">가나다라마바사아자차카타파하</p>
                        <p class="preview">0123456789</p>
                    </div>
                    <div class="box_02">
                        <p class="tit">
                            Urbanist<span>|</span><span>영문</span>
                        </p>
                        <p class="present">Aa</p>
                        <p class="weight">
                            <span class="me">Medium</span>
                        </p>
                        <p class="preview">
                            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt_section pages backgrounded">
            <div class="pt_inner"></div>
        </section>
        <!--section END-->
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/reference/referenceCommon.css"></style>
<style scoped src="@/assets/css/reference/referenceLayout.css"></style>

<style scoped>
.reference.hanvill .image_shadow {
    border-radius: 0;
    box-shadow: 2px 3px 10px #ddd;
}
.reference.hanvill .main_banner {
    background-image: url("~@/assets/images/reference/hanvill/img_mainBanner.jpg");
}
.reference.hanvill .concept .pt_inner::after {
    content: "";
    display: block;
    width: 100%;
    height: 25rem;
    background: url("~@/assets/images/reference/hanvill/concept.svg") no-repeat
        center/contain;
}
.reference.hanvill .main .mockup_wrap .mockup_basic {
    justify-content: space-between;
}
.reference.hanvill .main .mockup_wrap .mockup_basic .mobile:nth-child(2) {
    margin-top: 3rem;
}
.reference.hanvill .main .mockup_wrap .mockup_basic .mobile:nth-child(3) {
    margin-top: 6rem;
}
.reference.hanvill .main .mockup_wrap .mockup_basic {
    margin-top: 6rem;
}
.reference.hanvill .brand {
    background: #f4f4f4;
}
.reference.hanvill .brand .mockup_wrap .mockup_basic {
    margin: 5rem 0;
}
.reference.hanvill .cs_sv .icon {
    margin: 0 auto 5rem;
    max-width: 13rem;
    max-height: 13rem;
}
.reference.hanvill .responsive .image_centered {
    margin-bottom: 7rem;
}
.reference.hanvill .colorType_01 .box:nth-child(1) {
    background: #5a76ae;
}
.reference.hanvill .colorType_01 .box:nth-child(2) {
    background: #94a22d;
}
.reference.hanvill .fontType_01 .box_01 {
    font-family: "Noto Sans KR", sans-serif;
}
.reference.hanvill .fontType_01 .box_02 {
    font-family: "Roboto", sans-serif;
}
.reference.hanvill .pages {
    background: url("~@/assets/images/reference/hanvill/pages.jpg") no-repeat
        center/cover;
}
.reference.hanvill .pages::before {
    background: none;
}
@media screen and (max-width: 768px) {
}
</style>
